import session from './session'
import { rootAPI } from './settings'

const getBrandSettings = () => {
  return session.get(rootAPI + 'brand')
}

const saveBrandSettings = params => {
  return session.post(rootAPI + 'brand/', params)
}

const getBrandCustomFontList = params => {
  return session.get(rootAPI + 'brand/fonts', { params })
}

const addBrandCustomFont = payload => {
  return session.post(rootAPI + 'brand/fonts', payload)
}

const deleteBrandCustomFont = payload => {
  return session.post(rootAPI + 'brand/fonts', payload)
}

const uploadFontImage = function(formData) {
  return session.post(rootAPI + 'brand/font-file', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

export { getBrandSettings, saveBrandSettings, getBrandCustomFontList, addBrandCustomFont, deleteBrandCustomFont, uploadFontImage }
