<template>
  <div class="max-w-screen-xl m-auto p-6" id="webPushProviderChannelPage">
    <div class="flex items-center justify-between">
      <h1 class="text-2xl">Web Push Provider</h1>
      <el-link href="https://docs.growlytics.in/channels/web-push-notifications" target="_blank" type="primary" class="text-sm">
        <i class="el-icon-notebook-2"></i>
        Web Push integration guide
      </el-link>
    </div>
    <div class="mt-4" style="min-height:500px;" v-loading="loadingData">
      <template v-if="loadedOk">
        <el-form label-position="top" :model="webPush" ref="webPushForm" :rules="webPushFormRules">
          <!-- Vapid Settings Card -->
          <div class="align-middle inline-block w-full">
            <div class="shadow-sm border overflow-hidden sm:rounded-lg bg-white">
              <div class="flex items-center justify-between bg-gray-50 p-3 border-b">
                <h2 class="">Configure Vapid Keys</h2>
                <el-button
                  class="generateKeyBtn font-normal"
                  plain
                  size="mini"
                  type="primary"
                  :loading="busyGeneratingCredential"
                  @click="generateWebPushVapidCred()"
                  >Generate Vapid Key Combination</el-button
                >
              </div>
              <el-row class="webPushSettings p-4 pb-0" :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Public Key" prop="settings.publicVapidKey">
                    <div class="serverKeyInput">
                      <el-input v-model="webPush.settings.publicVapidKey" />
                    </div>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Private Key" prop="settings.privateVapidKey">
                    <div class="apiKeyInput">
                      <el-input v-model="webPush.settings.privateVapidKey"></el-input>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>

          <!-- ******** Notification Opt-in Settings ********* -->
          <div class="align-middle inline-block w-full mt-7">
            <div class="shadow-sm border overflow-hidden sm:rounded-lg bg-white">
              <div class="flex items-center justify-between  bg-gray-50 p-4 border-b border-gray-200">
                <h2 class="">Notification Opt-in Settings</h2>
              </div>

              <!-- Select opt-in type -->
              <div class="border-b pt-4 pb-7 px-5">
                <RadioGroup v-model="webPush.settings.optinType">
                  <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                    <RadioGroupOption
                      as="template"
                      v-for="mailingList in optinTypes"
                      :key="mailingList.id"
                      :value="mailingList.id"
                      v-slot="{ checked, active }"
                    >
                      <div
                        :class="[
                          checked ? 'border-transparent' : 'border-gray-300',
                          active ? 'border-primary-500 ring-2 ring-primary-500' : '',
                          'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
                        ]"
                      >
                        <span class="flex-1 flex">
                          <span class="flex flex-col">
                            <RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900">
                              {{ mailingList.title }}
                            </RadioGroupLabel>
                            <RadioGroupDescription as="span" class="mt-1 flex items-center text-sm text-gray-500">
                              {{ mailingList.description }}
                            </RadioGroupDescription>
                          </span>
                        </span>
                        <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-primary-600']" aria-hidden="true" />
                        <span
                          :class="[
                            active ? 'border' : 'border-2',
                            checked ? 'border-primary-500' : 'border-transparent',
                            'absolute -inset-px rounded-lg pointer-events-none'
                          ]"
                          aria-hidden="true"
                        />
                      </div>
                    </RadioGroupOption>
                  </div>
                </RadioGroup>
              </div>

              <!-- One Step Optin -->
              <div class="flex mt-4 p-3 pl-5" v-if="webPush.settings.optinType == 'one-step'">
                <el-row :gutter="20" class="flex-1 pr-10">
                  <!-- Show At Page Load -->
                  <el-col :span="8">
                    <el-form-item label="Show dialog when page loads" prop="title">
                      <el-radio-group class="readioGroup" v-model="webPush.settings.autoAsk">
                        <el-radio :label="true">Yes</el-radio>
                        <el-radio :label="false">No</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>

                  <!-- Show after seconds -->
                  <el-col :span="8">
                    <el-form-item label="Show Permission Dialog After" prop="title">
                      <div style="width: 300px">
                        <el-input type="number" v-model="webPush.settings.showAfterSeconds" placeholder="">
                          <template #append>Seconds after page loads</template>
                        </el-input>
                      </div>
                    </el-form-item>
                  </el-col>

                  <!-- Show permission dialog again after -->
                  <el-col :span="8">
                    <el-form-item label="Show Permission Dialog Again After" prop="title">
                      <div style="width: 170px">
                        <el-input type="number" v-model="webPush.settings.askAgainAfter" placeholder="">
                          <template #append>Hours</template>
                        </el-input>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>

              <!-- Two Step Optin -->
              <div class="flex mt-4 p-3 pl-5" v-else-if="webPush.settings.optinType == 'two-step'">
                <div class="flex-1 pr-10">
                  <!-- Show after seconds -->
                  <el-row>
                    <el-col :span="12">
                      <!-- Show At Page Load -->
                      <el-form-item label="Show dialog when page loads" prop="title">
                        <el-radio-group class="readioGroup" v-model="webPush.settings.autoAsk">
                          <el-radio :label="true">Yes</el-radio>
                          <el-radio :label="false">No</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Show Permission Dialog Again After" prop="title">
                        <div style="width: 170px">
                          <el-input type="number" v-model="webPush.settings.askAgainAfter" placeholder="">
                            <template #append>Hours</template>
                          </el-input>
                        </div>
                      </el-form-item>
                    </el-col>

                    <!-- Show permission dialog again after -->
                    <el-col :span="12">
                      <el-form-item label="Show Permission Dialog After" prop="title">
                        <div style="width: 300px">
                          <el-input type="number" v-model="webPush.settings.showAfterSeconds" placeholder="">
                            <template #append>Seconds after page loads</template>
                          </el-input>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!-- Title -->
                  <el-form-item label="Title Text" prop="settings.title">
                    <el-input
                      spellcheck="false"
                      v-model="webPush.settings.title"
                      maxlength="50"
                      :show-word-limit="true"
                    ></el-input>
                  </el-form-item>
                  <!-- Message -->
                  <el-form-item label="Message Text" prop="settings.message">
                    <el-input
                      spellcheck="false"
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 2 }"
                      resize="none"
                      maxlength="100"
                      :show-word-limit="true"
                      v-model="webPush.settings.message"
                    ></el-input>
                  </el-form-item>
                  <!-- Icon Url -->
                  <el-form-item label="Icon Url" prop="settings.icon">
                    <el-input spellcheck="false" v-model="webPush.settings.icon"></el-input>
                  </el-form-item>
                  <!-- Accept Button -->
                  <el-form-item label="Accept Button Text" prop="settings.acceptText">
                    <el-input
                      spellcheck="false"
                      v-model="webPush.settings.acceptText"
                      maxlength="20"
                      :show-word-limit="true"
                    ></el-input>
                  </el-form-item>
                  <!-- Deny Button -->
                  <el-form-item label="Deny Button Text" prop="settings.denyText">
                    <el-input
                      spellcheck="false"
                      v-model="webPush.settings.denyText"
                      maxlength="20"
                      :show-word-limit="true"
                    ></el-input>
                  </el-form-item>
                  <!-- Display Position For Mobile -->
                  <el-row class="justify-between" id="displayPosition">
                    <el-col>
                      <el-form-item label="Dialog Display Position - Desktop">
                        <el-select class="w-full" v-model="webPush.settings.displayPositionv1.desktop">
                          <el-option
                            v-for="(item, index) in displayPositionList.desktop"
                            :key="index"
                            :label="item"
                            :value="index"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col>
                      <el-form-item label="Dialog Display Position - Mobile">
                        <div class="serverKeyInput">
                          <el-select class="w-full" v-model="webPush.settings.displayPositionv1.mobile">
                            <el-option
                              v-for="(item, index) in displayPositionList.mobile"
                              :key="index"
                              :label="item"
                              :value="index"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>

                <!-- Right Side Preview -->
                <div class="w-auto pl-2 pt-2 pr-8">
                  <WPNotificationBox :webPush="webPush" />
                  <!-- Theme Color Picker -->
                  <div class="mt-4 text-center">
                    <div class="inline-flex items-center bg-gray-200 rounded border">
                      <div class="px-4 text-sm">Theme Color</div>
                      <div class="bg-white px-4 flex items-center">
                        <el-color-picker
                          ref="colorPickerEle"
                          popper-class="webpush-colorpicker"
                          size="medium"
                          v-model="webPush.settings.color"
                          @active-change="onThemeColorChange"
                        />
                        <a class="ml-2 text-gray-700" @click="openColorPicker">{{ webPush.settings.color }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Self Handled Optin -->
              <div v-else class="text-center py-10">
                Click on "Save" and please go through our
                <a
                  class="text-secondary underline"
                  target="_blank"
                  href="https://docs.growlytics.in/channels/web-push-notifications#step-3-requesting-user-permissions"
                  >technical documentation</a
                >
                for showing opt-in dialog using javascript.
              </div>
            </div>
          </div>

          <!--Submit Btn -->
          <div class="text-center mt-10">
            <el-button :loading="busySubmitting" @click="submitWebPushDetail('webPushForm')" type="primary"
              >Save Changes</el-button
            >
          </div>
        </el-form>
      </template>
      <template v-if="loadedError">
        <CLoadErrorTemp />
      </template>
    </div>
  </div>
</template>
<script>
import webPushPage from './webPushPage'
export default webPushPage
</script>
<style lang="scss">
#webPushProviderChannelPage {
  .el-card__header {
    background: #e9eff1;
  }
  .el-card__body label {
    color: #909399f2;
  }
  .el-loading-mask {
    background: transparent;
  }
  #displayPosition {
    &:before,
    &:after {
      content: none;
    }
    .el-col {
      max-width: 48.5%;
      flex: 0 0 48.5%;
    }
  }
  .el-color-picker__trigger {
    padding: 8px;
  }
}
</style>
