import session from './session'
import { rootAPI } from './settings'

const login = params => {
  return session.post(rootAPI + 'auth/login', params, {
    withCredentials: true
  })
}

const generateWebPushVapidCred = params => {
  return session.get(rootAPI + 'channels//webPush/credentials/generate')
}

const fetchWebPushVapidProviderDetails = params => {
  return session.get(rootAPI + `channels/${params.providerType}`)
}

const fetchProviderDetailItem = (providerType, providerId) => {
  return session.get(rootAPI + `channels/${providerType}/${providerId}`)
}

const saveChannelProviderInfo = body => {
  return session.put(rootAPI + 'channels/', body)
}

const createProviderItem = body => {
  return session.post(rootAPI + 'channels/', body)
}

const deleteProviderItem = (providerType, providerId) => {
  return session.delete(rootAPI + `channels/${providerType}/${providerId}`)
}

const unarchiveProviderItem = (providerType, providerId) => {
  return session.put(rootAPI + `channels/${providerType}/unarchive/${providerId}`)
}

const validateElasticEmail = (providerName, emailStr) => {
  const requestBody = {
    settings: {
      domain: emailStr
    },
    channel_type: 'email',
    provider_name: providerName,
    provider_type: 'growlytics-email'
  }
  var customSession = session
  customSession.defaults.timeout = 60000
  return customSession.post(rootAPI + 'elasticEmail/domain/verify', requestBody)
}

const verifySocialIntegration = channelId => {
  const requestBody = { channelId }
  var customSession = session
  customSession.defaults.timeout = 60000
  return customSession.put(rootAPI + 'channels/verify', requestBody)
}

export {
  login,
  fetchWebPushVapidProviderDetails,
  fetchProviderDetailItem,
  createProviderItem,
  deleteProviderItem,
  unarchiveProviderItem,
  saveChannelProviderInfo,
  validateElasticEmail,
  generateWebPushVapidCred,
  verifySocialIntegration
}
