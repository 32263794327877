import router from '@/router'
import HomePage from './HomePage.vue'

router.addRoute({
  path: '/',
  name: 'HomePage',
  component: HomePage,
  meta: {
    layout: 'dashboard'
  }
})
