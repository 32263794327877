import router from '@/router'
import EmailPage from './views/EmailPage.vue'
import EmailDetailPage from './views/EmailDetailPage.vue'

router.addRoute({
  path: '/channels/email',
  name: 'EmailPage',
  component: EmailPage,
  meta: {
    layout: 'dashboard'
  }
})

router.addRoute({
  path: '/channels/email/:id',
  name: 'EmailDetailPage',
  component: EmailDetailPage,
  meta: {
    layout: 'dashboard'
  }
})
