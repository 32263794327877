import session from './session'
import { rootAPI } from './settings'

const fetchUserList = params => {
  return session.get(rootAPI + 'users', { params })
}

const getBrandSettings = () => {
  return session.get(rootAPI + 'brand')
}

const saveBrandSettings = params => {
  return session.post(rootAPI + 'brand/', params)
}

const inviteNewUser = params => {
  return session.post(rootAPI + 'users/invite/', params)
}

const resendInviteUser = ({ userId, role }) => {
  return session.post(rootAPI + `users/${userId}/re-invite`, {
    role
  })
}

const deleteInvitationUser = userId => {
  return session.delete(rootAPI + `users/invitation/${userId}`)
}

const updateUser = (userId, body) => {
  return session.put(rootAPI + `users/${userId}/`, body)
}

const updateInvitation = ({ userId, body }) => {
  return session.put(rootAPI + `users/invitation/${userId}/`, body)
}

const deleteUser = userId => {
  return session.delete(rootAPI + `users/${userId}/`)
}

export {
  fetchUserList,
  getBrandSettings,
  saveBrandSettings,
  inviteNewUser,
  deleteInvitationUser,
  resendInviteUser,
  updateInvitation,
  updateUser,
  deleteUser
}
