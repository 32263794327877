<template>
  <ChannelStandardListPage
    detailRouteName="WhatsAppDetailPage"
    channelId="whatsapp"
    page-title="WhatsApp Channels"
    textNodata="No WhatsApp Chanels Configured Yet"
  />
</template>
<script>
import ChannelStandardListPage from '@/core/components/ChannelStandardListPage.vue'
export default {
  components: {
    ChannelStandardListPage
  }
}
</script>
