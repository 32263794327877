<template>
  <div class="max-w-screen-xl m-auto p-6" id="webPushProviderChannelPage">
    <div class="flex items-center justify-between">
      <h1 class="text-2xl"></h1>
    </div>
    <div class="mt-4" style="min-height:500px;" v-loading="loadingData">
      <template v-if="loadedOk">
        <el-form
          @submit.prevent=""
          label-position="top"
          :model="form"
          ref="couponForm"
          :rules="couponFormRules"
          @keypress.enter="saveCouponSettings"
        >
          <!-- Vapid Settings Card -->
          <div class="align-middle inline-block w-full">
            <div class="shadow overflow-hidden border-b border-gray-100 sm:rounded-lg bg-white">
              <div class="flex items-center justify-between bg-gray-50 p-3 border-b border-gray-200">
                <h2 class="">Configure Coupon Webhook</h2>
                <span class="generateKeyBtn font-normal" plain size="mini" type="primary">
                  <el-link
                    href="https://docs.growlytics.in/channels/web-push-notifications"
                    target="_blank"
                    type="primary"
                    class="text-sm"
                  >
                    <i class="el-icon-notebook-2"></i>
                    How to setup coupon webhooks?
                  </el-link>
                </span>
              </div>

              <div class="p-7">
                <el-form-item label="Coupon Webhook Url" prop="couponWebhook">
                  <el-input
                    v-model="form.couponWebhook"
                    placeholder="Enter coupon webhook url (Ex: https://your-coupon-hosting-server.com/generateCoupon)"
                  />
                </el-form-item>
              </div>
            </div>
          </div>

          <!--Submit Btn -->
          <div class="text-center mt-10">
            <el-button :loading="savingData" @click="saveCouponSettings()" type="primary">Save Changes</el-button>
          </div>
        </el-form>
      </template>
      <template v-else>
        <CLoadErrorTemp />
      </template>
    </div>
  </div>
</template>
<script>
import couponPage from './couponPage'
export default couponPage
</script>
<style lang="scss">
#webPushProviderChannelPage {
  .el-card__header {
    background: #e9eff1;
  }
  .el-card__body label {
    color: #909399f2;
  }
  .el-loading-mask {
    background: transparent;
  }
  #displayPosition {
    &:before,
    &:after {
      content: none;
    }
    .el-col {
      max-width: 48.5%;
      flex: 0 0 48.5%;
    }
  }
  .el-color-picker__trigger {
    padding: 8px;
  }
}
</style>
