<template>
  <el-container>
    <router-view />
  </el-container>
</template>

<script>
export default {
  setup () {

  }
}
</script>
