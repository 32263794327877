<template>
  <div>
    <template v-if="item.type === 'input'">
      <el-input
        v-bind="item.attrs ? item.attrs : {}"
        :disabled="item.disabled"
        spellcheck="false"
        :placeholder="item.placeholder"
        v-model="dataValue"
      />
    </template>
    <template v-else-if="item.type === 'select'">
      <el-select
        v-bind="item.attrs ? item.attrs : {}"
        @change="onChangeSelect"
        :placeholder="item.placeholder"
        v-model="dataValue"
        :disabled="item.disabled"
      >
        <el-option v-for="opt in item.options" :key="opt.value" :label="opt.label" :value="opt.value" />
      </el-select>
    </template>
    <template v-else-if="item.type === 'component'">
      <component v-bind="item.attrs ? item.attrs : {}" :channelId="channelId" :is="item.component" />
    </template>
  </div>
</template>
<script>
import { computed, inject } from 'vue'
import DeliveryWebhookBlock from './DeliveryWebhookBlock.vue'
import VerifySocialMediaIntegrationBlock from './VerifySocialMediaIntegrationBlock.vue'
import EmailGrowlyticsDNS from './EmailGrowlyticsDNS.vue'
import AddSendingDomainText from './AddSendingDomainText.vue'
export default {
  components: {
    DeliveryWebhookBlock,
    EmailGrowlyticsDNS,
    AddSendingDomainText,
    VerifySocialMediaIntegrationBlock
  },
  props: {
    modelValue: [String],
    item: {
      type: Object,
      required: true
    },
    channelId: {
      required: false,
      type: String,
      default: null
    }
  },
  emits: ['update:modelValue', 'change:select'],
  setup(props, { emit }) {
    const metadata = inject('metadata')
    const providerKey = computed(() => metadata.fixedHeader.find(o => o.key === 'provider_type')?.value)
    const dataValue = computed({
      get: () => props.modelValue,
      set: val => emit('update:modelValue', val)
    })
    function onChangeSelect(val) {
      emit('change:select', { item: props.item, value: val })
    }
    return {
      providerKey,
      dataValue,
      onChangeSelect
    }
  }
}
</script>
