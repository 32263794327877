import session from './session'
import { rootAPI } from './settings'

const login = params => {
  return session.post(rootAPI + 'auth/login', params, {
    withCredentials: true
  })
}

const getToken = () => {
  return session.get(rootAPI + 'auth/token', {
    withCredentials: true
  })
}

export { login, getToken }
