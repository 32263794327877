/* Install global components */
import LayoutPage from './LayoutPage.vue'
import LoadErrorTemp from './LoadErrorTemp.vue'
import CProviderForm from './CProviderForm.vue'
import Spinner from './Spinner.vue'

const installComponents = app => {
  app.component(LayoutPage.name, LayoutPage)
  app.component(LoadErrorTemp.name, LoadErrorTemp)
  app.component(CProviderForm.name, CProviderForm)
  app.component(Spinner.name, Spinner)
}

export default installComponents
