<template>
  <ChannelStandardListPage
    detailRouteName="SMSDetailPage"
    channelId="sms"
    page-title="SMS Channels"
    textNodata="No SMS Chanels Configured Yet"
  />
</template>
<script>
import ChannelStandardListPage from '@/core/components/ChannelStandardListPage.vue'
export default {
  components: {
    ChannelStandardListPage
  },
  setup() {}
}
</script>
