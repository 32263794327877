import { requiredRule, minMaxLengthRule } from './utils'
import { deliveryWebhookMetadata } from './static_metadata'

const whatsAppMetadata = {
  channel_type: 'whatsApp', // Enter Channel Name
  fixedHeader: [
    {
      type: 'select',
      key: 'provider_type', // IMPORTANT: Cant change this
      value: 'whatsapp_gupshup_v1',
      label: 'Select WhatsApp Provider',
      placeholder: 'Please select',
      options: [
        {
          value: 'whatsapp_gupshup_v1',
          label: 'Gupshup'
        },
        {
          value: 'whatsapp_gupshup_enterprise',
          label: 'Gupshup Enterprise'
        },
        {
          value: 'whatsapp_freshchat',
          label: 'Fresh Chat'
        },
        {
          value: 'whatsapp_zoko',
          label: 'Zoko'
        },
        {
          value: 'whatsapp_wati',
          label: 'Wati'
        },
        {
          value: 'whatsapp_interakt',
          label: 'Interakt'
        },
        {
          value: 'whatsapp_businessonbot',
          label: 'Business On Bot'
        },
        {
          value: 'whatsapp_dialog360',
          label: 'Dialog 360'
        },
        {
          value: 'whatsapp_gupshup',
          label: 'Gupshup Old - Depricated'
        },
        {
          value: 'whatsapp_valuefirst',
          label: 'ValueFirst'
        }
      ],
      rules: [requiredRule('Enter Provider Type')]
    },
    {
      type: 'input',
      key: 'provider_name', // Key to send request body to server
      value: '',
      label: 'Name Your Configuration',
      placeholder: 'Give provider name',
      rules: [requiredRule('Configuration Name'), minMaxLengthRule('Aws access key', 1, 2000)]
    }
  ],
  dynamicProviders: {
    whatsapp_gupshup_v1: {
      fields: [
        {
          type: 'input',
          key: 'apiKey',
          value: '',
          label: 'Api Key',
          placeholder: 'Api Key',
          rules: [requiredRule('Gupshup Api key'), minMaxLengthRule('Api key', 1, 5000)]
        },
        {
          type: 'input',
          key: 'appName',
          value: '',
          label: 'Gupshup App Name',
          placeholder: 'Gupshup App Name',
          rules: [requiredRule('Gupshup App Name'), minMaxLengthRule('Gupshup App Name', 1, 100)]
        },
        {
          type: 'input',
          key: 'fromNumber',
          value: '',
          label: 'Approved WhatsApp Number',
          placeholder: 'Approved WhatsApp Number',
          rules: [requiredRule('Approved WhatsApp Number'), minMaxLengthRule('Approved WhatsApp Number', 1, 1000)]
        },
        deliveryWebhookMetadata({
          attrs: {
            emailProviderType: 'whatsapp-gupshup'
          }
        })
      ]
    },
    whatsapp_gupshup: {
      fields: [
        {
          type: 'input',
          key: 'apiKey',
          value: '',
          label: 'Api Key',
          placeholder: 'Api Key',
          rules: [requiredRule('Gupshup Api key'), minMaxLengthRule('Api key', 1, 5000)]
        },
        {
          type: 'input',
          key: 'appName',
          value: '',
          label: 'Gupshup App Name',
          placeholder: 'Gupshup App Name',
          rules: [requiredRule('Gupshup App Name'), minMaxLengthRule('Gupshup App Name', 1, 100)]
        },
        {
          type: 'input',
          key: 'fromNumber',
          value: '',
          label: 'Approved WhatsApp Number',
          placeholder: 'Approved WhatsApp Number',
          rules: [requiredRule('Approved WhatsApp Number'), minMaxLengthRule('Approved WhatsApp Number', 1, 1000)]
        },
        deliveryWebhookMetadata({
          attrs: {
            emailProviderType: 'whatsapp-gupshup'
          }
        })
      ]
    },
    whatsapp_gupshup_enterprise: {
      fields: [
        {
          type: 'input',
          key: 'userid',
          value: '',
          label: 'Gupshup User Id',
          placeholder: 'Enter Gupshup User Id',
          rules: [requiredRule('User Id'), minMaxLengthRule('User Id', 1, 5000)]
        },
        {
          type: 'input',
          key: 'password',
          value: '',
          label: 'Password',
          placeholder: 'Gupshup Password',
          rules: [requiredRule('Password'), minMaxLengthRule('Password', 1, 100)]
        },
        deliveryWebhookMetadata({
          attrs: {
            emailProviderType: 'whatsapp-gupshup-entrprise'
          }
        })
      ]
    },
    whatsapp_freshchat: {
      fields: [
        {
          type: 'input',
          key: 'host',
          value: '',
          label: 'API Endpoint',
          placeholder: 'https://api.freshchat.com',
          rules: [requiredRule('API Endpoint'), minMaxLengthRule('API Endpoint', 1, 2000)]
        },
        {
          type: 'input',
          key: 'apiToken',
          value: '',
          label: 'API Token',
          placeholder: 'API Token',
          rules: [requiredRule('Freshchat API Token'), minMaxLengthRule('API Token', 1, 2000)]
        },
        {
          type: 'input',
          key: 'namespace',
          value: '',
          label: 'Namespace',
          placeholder: 'Namespace',
          rules: [requiredRule('Namespace'), minMaxLengthRule('Namespace', 1, 2000)]
        },
        {
          type: 'input',
          key: 'fromNumber',
          value: '',
          label: 'WhatsApp Number',
          placeholder: 'WhatsApp Number',
          rules: [requiredRule('WhatsApp Number'), minMaxLengthRule('WhatsApp Number', 1, 100)]
        },
        {
          type: 'input',
          key: 'languagePolicy',
          value: 'deterministic',
          label: 'Language Policy',
          placeholder: 'Language Policy',
          rules: [requiredRule('Language Policy'), minMaxLengthRule('Language Policy', 1, 1000)]
        },
        {
          type: 'input',
          key: 'languageCode',
          value: 'en',
          label: 'Language Code',
          placeholder: 'Language Code',
          rules: [requiredRule('Language Code'), minMaxLengthRule('Language Code', 1, 1000)]
        },
        deliveryWebhookMetadata({
          attrs: {
            emailProviderType: 'whatsapp-freshchat'
          }
        })
      ]
    },
    whatsapp_zoko: {
      fields: [
        {
          type: 'input',
          key: 'apiKey',
          value: '',
          label: 'Zoko Api Key',
          placeholder: 'Enter Zoko Api Key',
          rules: [requiredRule('Api key'), minMaxLengthRule('Api key', 1, 5000)]
        }
      ]
    },
    whatsapp_interakt: {
      fields: [
        {
          type: 'input',
          key: 'apiKey',
          value: '',
          label: 'Api Key',
          placeholder: 'Enter Api Key',
          rules: [requiredRule('Api key'), minMaxLengthRule('Api key', 1, 5000)]
        },
        deliveryWebhookMetadata({
          attrs: {
            emailProviderType: 'whatsapp-interakt'
          }
        })
      ]
    },
    whatsapp_businessonbot: {
      fields: [
        {
          type: 'input',
          key: 'host',
          value: '',
          label: 'Api Endpoint',
          placeholder: '',
          rules: [requiredRule('Api Endpoint'), minMaxLengthRule('Api Endpoint', 1, 5000)]
        },
        {
          type: 'input',
          key: 'apiKey',
          value: '',
          label: 'Api Key',
          placeholder: 'Enter Api Key',
          rules: [requiredRule('Api key'), minMaxLengthRule('Api key', 1, 5000)]
        },
        deliveryWebhookMetadata({
          attrs: {
            emailProviderType: 'whatsapp-bob'
          }
        })
      ]
    },
    whatsapp_dialog360: {
      fields: [
        {
          type: 'input',
          key: 'host',
          value: '',
          label: 'Api Endpoint',
          placeholder: '',
          rules: [requiredRule('Api Endpoint'), minMaxLengthRule('Api Endpoint', 1, 5000)]
        },
        {
          type: 'input',
          key: 'apiKey',
          value: '',
          label: 'Api Key',
          placeholder: 'Enter Api Key',
          rules: [requiredRule('Api key'), minMaxLengthRule('Api key', 1, 5000)]
        },
        deliveryWebhookMetadata({
          attrs: {
            emailProviderType: 'whatsapp-dialog360'
          }
        })
      ]
    },
    whatsapp_wati: {
      fields: [
        {
          type: 'input',
          key: 'host',
          value: '',
          label: 'Api Endpoint',
          placeholder: 'https://live-server-2454.wati.io',
          rules: [requiredRule('Api Endpoint'), minMaxLengthRule('Api Endpoint', 1, 5000)]
        },
        {
          type: 'input',
          key: 'token',
          value: '',
          label: 'Access Token',
          placeholder: 'Bearer xyz....',
          rules: [requiredRule('Access Token'), minMaxLengthRule('Access Token', 1, 10000)]
        },
        deliveryWebhookMetadata({
          attrs: {
            emailProviderType: 'whatsapp-wati'
          }
        })
      ]
    },
    whatsapp_valuefirst: {
      fields: [
        {
          type: 'input',
          key: 'userid',
          value: '',
          label: 'ValueFirst User Name',
          placeholder: 'Enter ValueFirst User Name',
          rules: [requiredRule('User Name'), minMaxLengthRule('User Name', 1, 5000)]
        },
        {
          type: 'input',
          key: 'password',
          value: '',
          label: 'ValueFirst Password',
          placeholder: 'ValueFirst Password',
          rules: [requiredRule('Password'), minMaxLengthRule('Password', 1, 100)]
        },
        {
          type: 'input',
          key: 'token',
          value: '',
          label: 'ValueFirst Access Token',
          placeholder: 'Access Token',
          rules: [requiredRule('ValueFirst Api key'), minMaxLengthRule('Api key', 1, 5000)]
        },
        {
          type: 'input',
          key: 'fromNumber',
          value: '',
          label: 'Approved WhatsApp Number',
          placeholder: 'Approved WhatsApp Number',
          rules: [requiredRule('Approved WhatsApp Number'), minMaxLengthRule('Approved WhatsApp Number', 1, 1000)]
        },
        deliveryWebhookMetadata({
          attrs: {
            emailProviderType: 'whatsapp-valuefirst'
          }
        })
      ]
    }
  }
}

export default whatsAppMetadata
