import { requiredRule, minMaxLengthRule } from './utils'
import { verifySocialMediaIntegration } from './static_metadata'
const socialProviderMetadata = {
  channel_type: 'social',
  fixedHeader: [
    {
      type: 'select',
      key: 'provider_type', // IMPORTANT: Cant change this
      value: 'facebook',
      label: 'Social Media',
      placeholder: 'Please select',
      options: [
        {
          value: 'facebook',
          label: 'Facebook'
        }
        // {
        //   value: 'google',
        //   label: 'Google'
        // }
      ],
      rules: [requiredRule('Social Media Type')]
    },
    {
      type: 'input',
      key: 'provider_name', // Key to send request body to server
      value: '',
      label: 'Name Your Configuration',
      placeholder: 'Give provider name',
      rules: [requiredRule('Name Your Configuration'), minMaxLengthRule('Aws access key', 1, 2000)]
    }
  ],
  dynamicProviders: {
    facebook: {
      fields: [
        {
          type: 'input',
          key: 'accountId', // Key to send request body to server
          value: '',
          label: 'Facebook Ad Account ID',
          placeholder: 'Enter Your Facebook Ad Account ID',
          rules: [requiredRule('Ad Account ID'), minMaxLengthRule('Ad Account ID', 1, 5000)]
        },
        {
          type: 'input',
          key: 'accessToken', // Key to send request body to server
          value: '',
          label: 'Facebook App Access Token',
          placeholder: 'Enter Facebook App Access Token',
          rules: [requiredRule('Access Token'), minMaxLengthRule('Access Token', 1, 5000)]
        }
      ]
    },
    google: {
      fields: [
        {
          type: 'input',
          key: 'businessId',
          value: '',
          label: 'Ad Account ID',
          placeholder: 'Ad Account ID',
          rules: [requiredRule('Ad Account ID'), minMaxLengthRule('Aws access key', 1, 5000)]
        },
        verifySocialMediaIntegration({
          attrs: {
            providerType: 'google'
          }
        })
      ]
    }
  }
}
export default socialProviderMetadata
