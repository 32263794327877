const fallbackFonts = [
  'Arial',
  'Arial Black',
  'Century Gothic',
  'Courier',
  'Courier New',
  'Georgia',
  'Geneva',
  'Helvetica',
  'Helvetica Neue',
  'Lucida',
  'Lucida Grande',
  'Lucida Sans',
  'MS Serif',
  'New York',
  'Palatino',
  'Palatino Linotype',
  'Tahoma',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana'
]

const defaultForm = () => {
  return {
    name: '',
    url: '',
    fallback: fallbackFonts[0]
  }
}
export { defaultForm, fallbackFonts }
