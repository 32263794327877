export const deliveryWebhookMetadata = args => {
  return {
    type: 'component',
    key: 'delivery-webhook-component',
    component: 'DeliveryWebhookBlock',
    attrs: args.attrs
  }
}

export const verifySocialMediaIntegration = args => {
  return {
    type: 'component',
    key: 'verify-social-media-component',
    component: 'VerifySocialMediaIntegrationBlock',
    attrs: args.attrs
  }
}

export const sendingDomainTextMetadata = {
  type: 'component',
  key: 'sending-domain-component',
  component: 'AddSendingDomainText'
}

export const DNSRecordMetadata = {
  type: 'component',
  key: 'dns-record-component',
  component: 'EmailGrowlyticsDNS'
}
