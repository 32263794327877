import router from '@/router'
import CouponPage from './CouponPage.vue'

router.addRoute({
  path: '/coupon',
  name: 'CouponPage',
  component: CouponPage,
  meta: {
    layout: 'dashboard'
  }
})
