import router from '@/router'
import WebPushPage from './WebPushPage.vue'

router.addRoute({
  path: '/channels/webPush',
  name: 'WebpushPage',
  component: WebPushPage,
  meta: {
    layout: 'dashboard'
  }
})
