import router from '@/router'
import CampaignSettingsPage from './campaignSettingsPage.vue'

router.addRoute({
  path: '/campaign-settings',
  name: 'CampaignSettingsPage',
  component: CampaignSettingsPage,
  meta: {
    layout: 'dashboard'
  }
})
