<template>
  <div class="flex flex-col items-center justify-center py-20">
    <img src="/img/maintainance.png" class="my-4 w-40" />
    <div class="font-bold text-primary-600 text-4xl">Sorry, looks like something's wrong.</div>
    <p class="text-gray-600 mt-2">
      {{ `You shouldn't be seeing this message, please contact Growlytics support team if you are seeing this message.` }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'CLoadErrorTemp'
}
</script>
