<template>
  <el-container class="h-screen overflow-hidden" style="background-color: #f4f5f7">
    <el-aside width="245px" class="bg-white hidden md:block" v-if="sidebarVisiblity">
      <DashboardSidebar />
    </el-aside>
    <el-container>
      <el-main>
        <slot />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import DashboardSidebar from '../components/DashboardSidebar.vue'
import { sidebarVisiblity } from '@/services/settings'

export default {
  data() {
    return {
      sidebarVisiblity: sidebarVisiblity
    }
  },
  components: {
    DashboardSidebar
  },
  setup() {
    // Read token first if not available
  }
}
</script>
