<template>
  <el-dialog title="Update User" v-model="visible" width="500">
    <div class="p-5">
      <el-form :model="user" label-position="top">
        <el-form-item label="Email">
          <el-input disabled v-model="user.email"></el-input>
        </el-form-item>

        <!-- Marketing Role -->
        <div class="mt-4">
          <el-form-item label="Marketing Role">
            <el-radio-group v-model="user.role" size="medium" class="capitalize">
              <el-radio border label="none">No Access</el-radio>
              <el-radio border label="user">Collabortor</el-radio>
              <el-radio border label="admin"></el-radio>
            </el-radio-group>
          </el-form-item>

          <!-- Support Role -->
          <div class="mt-4">
            <el-form-item label="Support Role">
              <el-radio-group v-model="user.support_system_role" size="medium" class="capitalize">
                <el-radio border label="none">No Access</el-radio>
                <el-radio border label="agent"></el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>

    <template #footer>
      <el-button type="primary" @click="updateUser">Save Changes</el-button>
      <el-button @click="visible = false">Cancel</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { updateUser } from '@/services/userService'
export default {
  props: {},
  data() {
    return {
      user: null,
      visible: false
    }
  },

  methods: {
    showDialog(user) {
      this.user = user
      this.visible = true
    },

    async updateUser() {
      const userId = this.user.id
      const registerationStatus = this.user.status
      const body = {
        role: this.user.role,
        support_system_role: this.user.support_system_role,
        isInvitedUser: registerationStatus !== 'registered'
      }

      try {
        await updateUser(userId, body)
        this.$message.success('User updated successfully.')
        this.visible = false
        this.$emit('refresh:user-list')
      } catch (err) {}

      console.log('Update User', this.user)
    }
  },

  mounted() {}
}
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0;
}
</style>
