<template>
  <LayoutPage id="socialMediaDetailPage">
    <CProviderForm v-model="formData" :channelId="channelId" :buttons="buttons" :metadata="providerMetadata" />
  </LayoutPage>
</template>
<script>
import { reactive, ref, toRefs } from 'vue'
import { socialProviderMetadata } from '@/metadata'
import cloneDeep from 'lodash/cloneDeep'
import { useVModel } from '@/use/useVModel'
export default {
  props: {
    modelValue: {
      type: Object
    },
    buttons: {
      type: Array,
      default: () => []
    },
    channelId: {
      required: false,
      type: String,
      default: null
    }
  },
  setup(props) {
    const providerMetadata = ref(cloneDeep(socialProviderMetadata))
    const state = reactive({
      formData: useVModel(props, 'modelValue')
    })
    return {
      providerMetadata,
      ...toRefs(state)
    }
  }
}
</script>
