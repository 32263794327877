<template>
  <div>
    <div class="text-gray-600 text-sm mb-2">{{ `PLEASE ADD FOLLOWING DNS RECORDS TO YOUR DOMAIN.` }}</div>
    <table class="table-fixed w-full">
      <thead class="border-b border-solid border-gray-200">
        <td class="min-w-td py-2 px-2 text-gray-600 uppercase w-20">Type</td>
        <td class="min-w-td py-2 px-2 text-gray-600 uppercase w-40">Name</td>
        <td class="min-w-td py-2 px-2 text-gray-600 uppercase">Value</td>
      </thead>
      <tbody>
        <tr class="border-b border-solid border-gray-200">
          <td class="py-2 px-2 break-words">TXT</td>
          <td class="py-2 px-2 break-words">@</td>
          <td class="py-2 px-2 break-words">v=spf1 a mx include:growlytics.in ~all</td>
        </tr>
        <tr class="border-b border-solid border-gray-200">
          <td class="py-2 px-2 break-words">TXT</td>
          <td class="py-2 px-2 break-words">_dmarc</td>
          <td class="py-2 px-2 break-words">v=DMARC1;p=none;</td>
        </tr>
        <tr class="border-b border-solid border-gray-200">
          <td class="py-2 px-2 break-words">TXT</td>
          <td class="py-2 px-2 break-words">api._domainkey</td>
          <td class="py-2 px-2 break-words">
            k=rsa;t=s;p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCbmGbQMzYeMvxwtNQoXN0waGYaciuKx8mtMh5czguT4EZlJXuCt6V+l56mmt3t68FEX5JJ0q4ijG71BGoFRkl87uJi7LrQt1ZZmZCvrEII0YO4mp8sDLXC8g1aUAoi8TJgxq2MJqCaMyj5kAm3Fdy2tzftPCV/lbdiJqmBnWKjtwIDAQAB
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
