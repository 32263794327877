<template>
  <div>
    <div class="text-center">
      <i style="font-size: 200px" class="text-green-400 el-icon-circle-check" />
    </div>
    <div class="max-w-lg m-auto leading-10 text-gray-500 text-center mt-8">
      <div class="mt-12 text-sm">
        DOMAIN VERIFIED SUCCESSFULLY.
      </div>
      <div class="mt-8">
        <el-button class="uppercase" type="primary" @click="onContinue">{{
          `Continue`
        }}</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  emits: ['click:continue'],
  setup (props, { emit }) {
    function onContinue () {
      emit('click:continue')
    }
    return {
      onContinue
    }
  }
}
</script>
