<template>
  <div class="max-w-screen-lg m-auto" id="createSMSChannelPage">
    <!-- Header -->
    <div class="mt-6 mb-6 grid grid-cols-2 w-full">
      <div>
        <router-link :to="{ name: 'SMSListPage' }" class="text-gray-600 hover:text-black">
          <i class="text-2xl el-icon-back"></i>
        </router-link>
        <h1 class="text-2xl inline-block ml-2">
          {{ isCreatePage ? `Add SMS Provider` : `Edit SMS Provider` }}
        </h1>
      </div>
    </div>

    <div class="align-middle inline-block w-full">
      <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white">
        <div class="" style="min-height: 500px" v-loading="loading">
          <template v-if="loaded">
            <template v-if="errorLoad">
              <div>{{ `Something goes wrong` }}</div>
            </template>
            <template v-else>
              <div class="align-middle inline-block w-full">
                <CProviderForm v-model="formData" :buttons="formButtons" :metadata="smsMetadata" />
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance, onMounted } from 'vue'
import { fetchProviderDetailItem, createProviderItem, saveChannelProviderInfo } from '@/services/channelService'
import { useRouter, useRoute } from 'vue-router'
import { smsMetadata } from '@/metadata'
export default {
  setup() {
    const app = getCurrentInstance()
    const prototypes = app.appContext.config.globalProperties
    const router = useRouter()
    const route = useRoute()
    const loaded = ref(false)
    const loading = ref(false)
    const errorLoad = ref(false)
    const formData = ref({})
    const isCreatePage = computed(() => route.params.id === 'create')
    const listPageRouteName = 'SMSListPage'
    const createButtons = ref([
      { label: 'Create Provider', type: 'submit', isLoading: false, attrs: { type: 'primary' }, onClick: () => createNew() },
      { label: 'Cancel', type: 'cancel', isLoading: false, attrs: {}, onClick: () => router.push({ name: listPageRouteName }) }
    ])
    const saveButtons = ref([
      { label: 'Save Provider', type: 'submit', isLoading: false, attrs: { type: 'primary' }, onClick: () => saveChanges() },
      { label: 'Cancel', type: 'cancel', isLoading: false, attrs: {}, onClick: () => router.push({ name: listPageRouteName }) }
    ])
    const formButtons = computed(() => (isCreatePage.value ? createButtons.value : saveButtons.value))
    onMounted(() => {
      if (!isCreatePage.value) {
        initializeData()
      } else {
        loaded.value = true
      }
    })
    async function initializeData() {
      loading.value = true
      try {
        const { data } = await fetchProviderDetailItem(smsMetadata.channel_type, route.params.id)
        const result = data[0]
        formData.value = result
      } catch {
        errorLoad.value = true
      }
      loaded.value = true
      loading.value = false
    }
    async function createNew() {
      createButtons.value[0].isLoading = true
      try {
        await createProviderItem(formData.value)
        prototypes.$message({ type: 'success', message: 'Provider created successfully.' })
        router.push({ name: listPageRouteName })
      } catch (err) {
        prototypes.$alert("Something's not right! Please contact support.", 'Error', {
          confirmButtonText: 'OK'
        })
      }
      createButtons.value[0].isLoading = false
    }
    async function saveChanges() {
      saveButtons.value[0].isLoading = true
      try {
        await saveChannelProviderInfo({ id: route.params.id, ...formData.value })
        prototypes.$message({ type: 'success', message: 'Provider updated.' })
        router.push({ name: listPageRouteName })
      } catch (err) {
        console.log(err)
        prototypes.$alert("Something's not right! Please contact support.", 'Error', {
          confirmButtonText: 'OK'
        })
      }
      saveButtons.value[0].isLoading = false
    }
    return {
      loaded,
      loading,
      errorLoad,
      smsMetadata,
      isCreatePage,
      formData,
      formButtons
    }
  }
}
</script>
