import router from '@/router'
import IntegrationListView from './views/IntegrationListView.vue' // Import component
import IntegrationDetailView from './views/IntegrationDetailView.vue' // Import component

router.addRoute({
  path: '/integrations',
  name: 'IntegrationListView',
  component: IntegrationListView,
  meta: {
    layout: 'dashboard' // define layout
  }
})
router.addRoute({
  path: '/integrations/:integrationId',
  name: 'IntegrationDetailView',
  component: IntegrationDetailView,
  meta: {
    layout: 'dashboard' // define layout
  }
})
