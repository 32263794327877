<template>
  <LayoutPage>
    <h1 class="text-2xl">{{ `Integrations` }}</h1>
    <div class="align-middle inline-block min-w-full w-full p-3">
      <!-- Grid -->
      <div class="" v-if="loaded">
        <template v-for="integration in integrationItems" :key="integration.id">
          <el-row class="mb-4 rounded-lg border border-gray-200 bg-white shadow-sm" :gutter="20">
            <el-col :span="2" class="p-3">
              <div
                class="h-20 w-20 bg-gray-300 rounded-md overflow-hidden bg-cover"
                :style="{ 'background-image': `url(${integration.icon}` }"
              ></div>
            </el-col>
            <el-col :span="18" class="p-3" style="display: flex; align-items: center">
              <div>
                <div class="flex-1 text-xs text-gray-500 capitalize">{{ integration.type + ' - ' + integration.subType }}</div>
                <div class="flex-1 font-semibold text-xl pb-1">{{ integration.title }}</div>
                <div class="flex-1 text-sm text-gray-500">{{ integration.description.short }}</div>
                <div class="flex justify-center bg-gray-100"></div>
              </div>
            </el-col>
            <el-col :span="4" style="display: flex; align-items: center">
              <div class="pr-4" style="margin-left: auto">
                <router-link :to="{ name: 'IntegrationDetailView', params: { integrationId: integration.key } }">
                  <el-button type="text" size="medium" v-if="integration.installed">
                    <span class="text-base">Edit Integration &nbsp;<i class="el-icon-right"></i></span>
                  </el-button>
                  <button
                    v-else
                    type="button"
                    class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Install
                  </button>
                </router-link>
              </div>
            </el-col>
          </el-row>
        </template>
      </div>

      <!-- Loader -->
      <template v-if="loading">
        <div class="my-16 relative" style="min-height: 500px">
          <Spinner />
        </div>
      </template>
      <template v-if="loadedError">
        <CLoadErrorTemp />
      </template>
    </div>
  </LayoutPage>
</template>
<script>
import { onMounted, ref } from 'vue'
import { getListIntegrations } from '@/services/integrationService'
export default {
  setup() {
    const integrationItems = ref([])
    const loadedError = ref(false)
    const loaded = ref(false)
    const loading = ref(false)
    onMounted(() => readListIntegrations())
    async function readListIntegrations() {
      try {
        loading.value = true
        const { data } = await getListIntegrations({})
        if (Array.isArray(data)) {
          integrationItems.value = data
          loaded.value = true
        } else {
          loadedError.value = true
        }
      } catch {
        loadedError.value = true
      } finally {
        loading.value = false
      }
    }
    return {
      loading,
      loadedError,
      loaded,
      integrationItems
    }
  }
}
</script>
