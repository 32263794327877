<template>
  <div>
    <div class="text-center">
      <i style="font-size: 200px" class="text-red-600 el-icon-warning" />
    </div>
    <div class="max-w-lg m-auto leading-10 text-gray-500 text-center mt-8">
      <div
        class="flex flex-col items-center text-center justify-center text-lg"
      >
        <div
          v-for="(error, errIdx) in errors"
          :key="errIdx"
        >
          {{ error }}
        </div>
      </div>
      <div class="mt-12 text-sm">
        IF YOU’VE RECENTLY ADDED, DNS RECORDS, IT MAY TAKE FEW SECONDS TO
        UPDATE.
      </div>
      <div class="mt-8">
        <el-button type="primary" @click="onClickTryAgain">{{
          `PLEASE TRY AGAIN AFTER SOME TIME`
        }}</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    errors: {
      type: Array,
      default: () => []
    }
  },
  emits: ['click:try-again'],
  setup (props, { emit }) {
    function onClickTryAgain () {
      emit('click:try-again')
    }
    return {
      onClickTryAgain
    }
  }
}
</script>
