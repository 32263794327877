<template>
  <div id="grw-webpush-box">
    <div class="grw-webpush-icon">
      <div
        v-if="webPush.settings.icon"
        class="grw-webpush-icon-img"
        :style="{ backgroundImage: 'url(' + webPush.settings.icon + ')!important' }"
      ></div>
      <div v-else class="grw-webpush-icon-img" :style="{ backgroundImage: 'url(' + defaultWebpushIconURL + ')!important' }"></div>
    </div>
    <div class="grw-webpush-content">
      <div class="grw-webpush-box-title">{{ webPush.settings.title }}</div>
      <div class="grw-webpush-box-description">{{ webPush.settings.message }}</div>
      <div class="grw-webpush-buttons">
        <button
          class="confirm"
          type="button"
          id="grw-webpush-confirm"
          :style="{ background: webPush.settings.color + ' !important', 'border-color': webPush.settings.color, color: 'white' }"
        >
          {{ webPush.settings.acceptText }}
        </button>
        <button
          class="cancel"
          type="button"
          id="grw-webpush-cancel"
          :style="{ color: webPush.settings.color + ' !important', 'border-color': webPush.settings.color }"
        >
          {{ webPush.settings.denyText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defaultWebpushIcon } from '../utils'
export default {
  props: {
    webPush: {
      type: Object,
      required: true
    }
  },
  setup() {
    const defaultWebpushIconURL = defaultWebpushIcon
    return {
      defaultWebpushIconURL
    }
  }
}
</script>
<style lang="scss">
#grw-webpush-box {
  background-color: #fff;
  font-family: Arial, sans-serif;
  width: auto;
  padding: 16px 15px 5px;
  border-radius: 7px 7px 7px 7px;
  overflow: hidden;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.22) !important;
  display: flex;
  flex-direction: row;
  .grw-webpush-icon {
    margin-right: 15px;
    height: auto;
  }
  .grw-webpush-icon-img {
    border-radius: 5px;
    height: 75px;
    width: 75px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .grw-webpush-content {
    flex: 1;
    text-align: left;
    width: 360px;
    .grw-webpush-box-title {
      color: #606060;
      font-size: 15px;
      animation: none;
      line-height: 22px;
      font-weight: 700;
      margin: 0 auto 10px;
      display: block;
    }
    .grw-webpush-box-description {
      color: #939393;
      font-size: 14px;
      font-weight: 300;
      animation: none;
      margin-bottom: 20px;
    }
    .grw-webpush-buttons {
      margin-bottom: 10px;
      button {
        background-color: #fff;
        color: #fff;
        border: 1px solid transparent;
        box-shadow: none !important;
        min-width: 130px;
        font-size: 14px;
        animation: none;
        font-weight: 100;
        border-radius: 3px;
        padding: 8px 10px;
        cursor: pointer;
        margin-left: 0;
        margin-right: 15px;
        letter-spacing: 0.7px;
      }
    }
  }
}
</style>
