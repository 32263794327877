import { requiredRule, minMaxLengthRule } from './utils'

const integrationDetailForm = {
  apiKey: {
    type: 'input',
    key: 'apiKey',
    value: '',
    label: 'Api Key',
    placeholder: 'Api Key',
    rules: [requiredRule('Api key'), minMaxLengthRule('Api key', 1, 1000)]
  },
  shopDomain: {
    type: 'input',
    key: 'shopDomain',
    value: '',
    label: 'Shop Domain',
    placeholder: 'Shop Domain',
    rules: [requiredRule('Shop Domain'), minMaxLengthRule('Api key', 1, 1000)]
  }
}

export { integrationDetailForm }
