import router from '@/router'
import SocialMediaView from './views/SocialMediaView.vue'
import SocialMediaCreateView from './views/SocialMediaCreateView.vue'
import SocialMediaEditView from './views/SocialMediaEditView.vue'

router.addRoute({
  path: '/channels/social-media',
  name: 'SocialMediaView',
  component: SocialMediaView,
  meta: {
    layout: 'dashboard'
  }
})

router.addRoute({
  path: '/channels/social-media/create',
  name: 'SocialMediaCreateView',
  component: SocialMediaCreateView,
  meta: {
    layout: 'dashboard'
  }
})

router.addRoute({
  path: '/channels/social-media/:id',
  name: 'SocialMediaEditView',
  component: SocialMediaEditView,
  meta: {
    layout: 'dashboard'
  }
})
