<template>
  <div></div>
</template>
<script>
import { login } from '@/services/authService'
export default {
  created() {
    login({
      email: 'ravi@growlytics.in',
      password: ''
    }).then(() => this.$router.push('/'))
  }
}
</script>
