<template>
  <LayoutPage id="createChannelPage">
    <!-- Header -->
    <div class="mt-6 mb-6 grid grid-cols-2 w-full">
      <div>
        <router-link :to="{ name: 'WhatsAppListPage' }" class="text-gray-600 hover:text-black">
          <i class="text-2xl el-icon-back"></i>
        </router-link>
        <h1 class="text-2xl inline-block ml-2">
          {{ isCreatePage ? `Add WhatsApp Provider` : `Update WhatsApp Provider` }}
        </h1>
      </div>
    </div>

    <div class="overflow-hidden border-gray-200 w-full rounded-lg bg-white">
      <div class="" style="min-height: 500px" v-loading="loading">
        <template v-if="loaded">
          <template v-if="errorLoad">
            <div>{{ `Something goes wrong` }}</div>
          </template>
          <template v-else>
            <div class="align-middle inline-block w-full">
              <CProviderForm v-model="formData" :buttons="formButtons" :metadata="whatsAppMetadata" />
            </div>
          </template>
        </template>
      </div>
    </div>
  </LayoutPage>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { ref, computed, getCurrentInstance, onMounted } from 'vue'
import { fetchProviderDetailItem, createProviderItem, saveChannelProviderInfo } from '@/services/channelService'
import { whatsAppMetadata } from '@/metadata'
export default {
  setup() {
    const app = getCurrentInstance()
    const prototypes = app.appContext.config.globalProperties
    const router = useRouter()
    const route = useRoute()
    const loaded = ref(false)
    const loading = ref(false)
    const errorLoad = ref(false)
    const formData = ref({})
    const isCreatePage = computed(() => route.params.id === 'create')
    const listPageRouteName = 'WhatsAppListPage'
    const createButtons = ref([
      { label: 'Create Provider', type: 'submit', isLoading: false, attrs: { type: 'primary' }, onClick: () => createNew() },
      { label: 'Cancel', type: 'cancel', isLoading: false, attrs: {}, onClick: () => router.push({ name: listPageRouteName }) }
    ])
    const saveButtons = ref([
      { label: 'Save Provider', type: 'submit', isLoading: false, attrs: { type: 'primary' }, onClick: () => saveChanges() },
      { label: 'Cancel', type: 'cancel', isLoading: false, attrs: {}, onClick: () => router.push({ name: listPageRouteName }) }
    ])
    const formButtons = computed(() => (isCreatePage.value ? createButtons.value : saveButtons.value))
    onMounted(() => {
      if (!isCreatePage.value) {
        initializeData()
      } else {
        loaded.value = true
      }
    })
    async function initializeData() {
      loading.value = true
      try {
        const { data } = await fetchProviderDetailItem(whatsAppMetadata.channel_type, route.params.id)
        const result = data[0]
        formData.value = result
      } catch {
        errorLoad.value = true
      }
      loaded.value = true
      loading.value = false
    }
    async function createNew() {
      createButtons.value[0].isLoading = true
      try {
        await createProviderItem(formData.value)
        prototypes.$message({ type: 'success', message: 'Provider created successfully.' })
        router.push({ name: listPageRouteName })
      } catch {
        prototypes.$alert('Something went wrong when creating SMS provider. Please try again later!', 'Error', {
          confirmButtonText: 'OK'
        })
      }
      createButtons.value[0].isLoading = false
    }
    async function saveChanges() {
      saveButtons.value[0].isLoading = true
      try {
        await saveChannelProviderInfo({ id: route.params.id, ...formData.value })
        prototypes.$message({ type: 'success', message: 'Provider updated successfully.' })
        router.push({ name: listPageRouteName })
      } catch (error) {
        console.log(error.response)
        if (error.response && error.response.status === 412) {
          prototypes.$alert(error.response.data.message, 'Error', {
            confirmButtonText: 'OK'
          })
        } else {
          prototypes.$alert("Something's not right! Please contact support.", 'Error', {
            confirmButtonText: 'OK'
          })
        }
      }
      saveButtons.value[0].isLoading = false
    }
    return {
      loaded,
      loading,
      errorLoad,
      whatsAppMetadata,
      isCreatePage,
      formData,
      formButtons
    }
  }
}
</script>
