<template>
  <div id="customFontsPage" class="relative">
    <div
      id="loadingLayer"
      v-if="loadingLayer"
      class="absolute inset-0 bg-white opacity-90 border bg-white"
      v-loading="loadingLayer"
    ></div>

    <div class="overflow-hidden bg-white" v-else>
      <!-- Loader -->
      <div style="min-height: 400px;" class="bg-white mt-2" v-if="loading" v-loading="loading"></div>

      <!-- Font list -->
      <div v-if="loaded">
        <template v-if="fontList.length > 0">
          <template v-for="(font, fontIdx) in fontList" :key="fontIdx">
            <div class="flex items-center border-b px-6 py-2 bg-white">
              <div class="flex-1 overflow-hidden">
                <div class="font-semibold text-base">{{ font.name }}</div>
                <div class="text-gray-600 text-sm">
                  <a target="_blank" :href="font.url" class="no-underline text-primary text-xs">Download Font File</a>
                </div>
              </div>
              <el-dropdown placement="bottom-end" trigger="click" @command="handleAction($event, font, fontIdx)">
                <i class="el-icon-more"></i>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item command="edit" icon="el-icon-edit">Update font</el-dropdown-item>
                    <el-dropdown-item command="delete" icon="el-icon-delete">Delete font</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </template>

          <!-- Header -->
          <div class="flex items-center justify-between bg-gray-50 py-3 px-5" v-if="fontList && fontList.length > 0">
            <span class="generateKeyBtn font-normal" plain size="mini" type="primary">
              <el-button size="small" type="primary" @click="onaddFont">Add Custom Fonts</el-button>
            </span>
          </div>
        </template>

        <div class="text-center bg-white p-5 py-32" v-if="!fontList.length">
          <i class="lni lni-text-format text-8xl text-gray-300"></i>

          <h3 class="mt-2 text-sm font-medium text-gray-400">No Fonts Added Yet.</h3>
          <div class="my-4 mx-auto leading-5 text-xs text-gray-600">
            Import custom web fonts to use them in emails. <br />Custom fonts can help keep your email look cohesive, aligning
            your email content with your ecommerce site.
          </div>

          <div class="mt-10">
            <el-button class="" type="primary" @click="onaddFont">Add Custom Fonts</el-button>
          </div>
        </div>
      </div>
      <template v-if="loadedError">
        <CLoadErrorTemp />
      </template>
    </div>
    <CustomFontNewDialog ref="customFontDialogRef" @onSaveChanges="patchFont" />
  </div>
</template>

<script>
import { getBrandCustomFontList, addBrandCustomFont } from '@/services/brandInfoService'
import CustomFontNewDialog from './CustomFontNewDialog.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { defaultForm } from './utils'
export default {
  data() {
    return {
      loadingLayer: false,
      isSaving: false,

      loading: false,
      loaded: false,
      loadedError: false,

      fontList: [],
      editValueForm: defaultForm(),
      editIndex: null,
      isPatchingFont: false
    }
  },
  components: {
    CustomFontNewDialog
  },
  methods: {
    async readFontList() {
      try {
        this.loading = true
        const { data } = await getBrandCustomFontList({})
        if (Array.isArray(data)) {
          this.fontList = data
          this.loaded = true
        } else {
          this.loadedError = true
        }
      } catch (err) {
        console.error('Failed to fetch font list', err)
        this.loadedError = true
      } finally {
        this.loading = false
      }
    },

    handleAction(action, font, fontIdx) {
      if (!font) return
      if (action === 'delete') {
        this.handleDelete(font, fontIdx)
      } else if (action === 'edit') {
        this.onUpdateFont(font, fontIdx)
      }
    },

    onaddFont() {
      this.$refs.customFontDialogRef.showDialog(null, false)
    },

    onUpdateFont(font, fontIdx) {
      this.$refs.customFontDialogRef.showDialog(font, true, fontIdx)
      this.dialogEditVisible = true
    },

    async patchFont({ form, type, index }) {
      try {
        this.isPatchingFont = true
        var payload = JSON.parse(JSON.stringify(this.fontList))
        if (type === 'add') {
          payload.push(form)
        } else if (type === 'edit') {
          payload[index] = form
        } else if (type === 'delete') {
          payload.splice(index, 1)
        } else {
          console.warning('type not correct (add, edit, delete)')
          return
        }
        const res = await addBrandCustomFont(payload)
        await this.readFontList()
        return res
      } catch (err) {
        console.log('patchFont', err)
        ElMessageBox.alert('Something went wrong while adding font. Please try again later!', 'Error', {
          confirmButtonText: 'OK'
        })
      } finally {
        this.isPatchingFont = false
      }
    },

    handleDelete(font, fontIdx) {
      ElMessageBox.confirm('Are you sure to delete this font?', 'Warning', {
        confirmButtonText: 'Delete font',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(async () => {
          this.loadingLayer = true
          await this.patchFont({ form: font, type: 'delete', index: fontIdx })
          ElMessage({ type: 'success', message: 'Delete completed' })
          this.loadingLayer = false
        })
        .catch(_ => {})
    }
  },

  mounted() {
    this.readFontList()
  }
}
</script>
