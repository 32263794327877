<template>
  <div style="min-height: 500px" v-loading="loading">
    <template v-if="loaded">
      <el-form
        :rules="rules"
        ref="formElement"
        :model="form"
        label-width="250px"
        class="bg-white pb-4 border rounded-lg overflow-hidden overflow-hidden"
      >
        <div class="bg-gray-50 p-7 pb-2 border-b border-gray-200 ">
          <template v-for="hItem in headerItems" :key="hItem.key">
            <el-form-item :label="hItem.label" :prop="hItem.key">
              <CProviderFormItem @change:select="onChangeSelect" :item="hItem" :channelId="channelId" v-model="form[hItem.key]" />
            </el-form-item>
          </template>
        </div>
        <div class="bg-white pt-7 pr-7 pb-7">
          <template v-for="item in dynamicItems" :key="item.key">
            <el-form-item :label="item.label" :prop="item.key">
              <CProviderFormItem @change:select="onChangeSelect" :channelId="channelId" :item="item" v-model="form[item.key]" />
            </el-form-item>
          </template>
        </div>
        <el-form-item class="mt-8" v-if="buttons.length">
          <div class="flex items-center">
            <template v-for="(button, bIdx) in buttons" :key="bIdx">
              <el-button v-bind="button.attrs" @click="onClickButton(button)" :loading="button.isLoading">{{
                button.label
              }}</el-button>
            </template>
          </div>
        </el-form-item>
      </el-form>
    </template>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted, provide, computed, watch, getCurrentInstance } from 'vue'
import CProviderFormItem from './CProviderFormItem.vue'
import { flattenObj } from '../utils'
export default {
  name: 'CProviderForm',
  components: {
    CProviderFormItem
  },
  props: {
    modelValue: {
      type: Object
    },
    metadata: {
      type: Object,
      required: true
    },
    buttons: {
      type: Array,
      default: () => []
    },
    channelId: {
      required: false,
      type: String,
      default: null
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const app = getCurrentInstance()
    const prototypes = app.appContext.config.globalProperties
    provide('metadata', props.metadata)
    const formElement = ref(null)
    const loading = ref(false)
    const loaded = ref(false)
    const lastProviderValue = ref({})
    const lastProviderKey = ref(null)
    const state = reactive({
      form: {},
      headerItems: {},
      dynamicItems: {},
      rules: {}
    })
    const fixedFields = computed(() => {
      const fixedHeader = props.metadata?.fixedHeader
      return fixedHeader.reduce((arr, o) => {
        arr.push(o.key)
        return arr
      }, [])
    })
    const dataHasChanged = computed(() =>
      Object.keys(lastProviderValue.value).some(key => lastProviderValue.value[key] !== state.form[key])
    )
    function onChangeProvider(providerKey) {
      if (dataHasChanged.value) {
        prototypes
          .$confirm('Changing provider type will clear existing input?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          })
          .then(() => {
            performChangeProvider(providerKey)
          })
          .catch(() => {
            state.form.provider_type = lastProviderKey.value
          })
      } else {
        performChangeProvider(providerKey)
      }
    }
    function resetLastProviderKey(providerKey) {
      lastProviderKey.value = providerKey
      const selectedProvider = props.metadata?.dynamicProviders[providerKey]
      if (!selectedProvider) {
        console.error(`Provider key: ${providerKey} not found in dynamicProviders metadata. Please check!`)
        return
      }
      if (!Array.isArray(selectedProvider.fields)) return
      lastProviderValue.value = selectedProvider.fields.reduce((obj, o) => {
        if (o.key && o.key !== 'component') {
          obj[o.key] = o.value
        }
        return obj
      }, {})
    }
    function performChangeProvider(providerKey) {
      resetLastProviderKey(providerKey)
      const metadata = props.metadata
      const selectedProvider = metadata.dynamicProviders[providerKey]
      const fixedHeader = metadata?.fixedHeader
      if (Array.isArray(selectedProvider?.fields)) {
        // Reset data, but keep data in fixed fields (provider type, name of configuration)
        // state.form = {}
        // state.rules = {}
        if (Array.isArray(fixedHeader)) {
          for (var key in state.form) {
            if (!fixedFields.value.includes(key)) delete state.form[key]
          }
        }
        /* End Reset data */
        state.dynamicItems = {}
        // Init new data
        selectedProvider.fields.forEach(it => {
          if (it.key) {
            state.form[it.key] = it.value
            state.rules[it.key] = it.rules
            state.dynamicItems[it.key] = it
          }
        })
        if (formElement.value) {
          formElement.value.clearValidate()
        }
      }
    }
    function onChangeSelect(evt) {
      if (evt?.item?.key === 'provider_type') {
        onChangeProvider(evt.value)
      }
    }
    function getInitData() {
      if (typeof props.modelValue !== 'object') return {}
      return flattenObj(props.modelValue)
    }
    function initializeData() {
      loading.value = true
      const initData = getInitData()
      const metadata = props.metadata
      const fixedHeader = metadata?.fixedHeader
      if (Array.isArray(fixedHeader)) {
        fixedHeader.forEach(item => {
          if (item.key) {
            state.form[item.key] = item.value
            state.rules[item.key] = item.rules
            state.headerItems[item.key] = item
          }
        })
        const providerKey = fixedHeader.find(o => o.key === 'provider_type')?.value
        if (providerKey) {
          lastProviderKey.value = providerKey
          const selectedProvider = initData.provider_type
            ? metadata.dynamicProviders[initData.provider_type]
            : metadata.dynamicProviders[providerKey]
          if (!selectedProvider) {
            console.error(`Provider key ${selectedProvider} not found in dynamicProviders metadata. Please check!`)
            return
          }
          selectedProvider.fields.forEach(it => {
            if (it.key) {
              state.form[it.key] = it.value
              state.rules[it.key] = it.rules
              state.dynamicItems[it.key] = it
              /* Update lastProviderValue */
              lastProviderValue.value[it.key] = it.value
            }
          })
        }
      }
      state.dynamicProviders = props.metadata.dynamicProviders
      if (typeof initData === 'object') {
        state.form = { ...state.form, ...initData }
      }
      loading.value = false
      loaded.value = true
    }
    onMounted(() => {
      initializeData()
      // initFromModelValue()
    })
    watch(
      () => state.form,
      newVal => {
        const metadata = props.metadata
        const fixedHeader = metadata?.fixedHeader
        var newForm = fixedHeader.reduce((obj, r) => {
          obj[r.key] = state.form[r.key]
          return obj
        }, {})
        Object.assign(newForm, { channel_type: metadata.channel_type })
        const currentProvider = metadata.dynamicProviders[state.form.provider_type]
        if (!currentProvider) {
          console.error(`Provider key ${state.form.provider_type} not found in dynamicProviders metadata. Please check!`)
          return
        }
        if (!Array.isArray(currentProvider?.fields)) return
        newForm.settings = currentProvider.fields.reduce((o, r) => {
          if (r.type !== 'component') {
            o[r.key] = state.form[r.key]
          }
          return o
        }, {})
        emit('update:modelValue', newForm)
      },
      { deep: true }
    )
    function onClickButton(button) {
      if (typeof button.onClick === 'function') {
        if (button.type === 'submit') {
          // Validate form before submit
          formElement.value.validate().then(() => {
            button.onClick()
          })
        } else {
          button.onClick()
        }
      }
    }
    return {
      ...toRefs(state),
      lastProviderValue,
      lastProviderKey,
      loading,
      loaded,
      formElement,
      // Functions
      onChangeSelect,
      onClickButton
    }
  }
}
</script>
<style lang="scss">
.el-form .el-form-item__label {
  padding-top: 8px;
}
</style>
