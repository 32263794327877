<template>
  <LayoutPage title="API Credentials">
    <div style="min-height: 500px" v-loading="loading">
      <template v-if="loadedOk">
        <div class="align-middle inline-block min-w-full w-full">
          <div class="shadow-sm border overflow-hidden sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                    API KEY
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                    ACCOUNT ID
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 whitespace-nowrap text-gray-600">{{ accountInfo.apiKey }}</td>
                  <td class="px-6 py-4 whitespace-nowrap  text-gray-600">{{ accountInfo.id }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template v-if="loadedError">
        <CLoadErrorTemp />
      </template>
    </div>
  </LayoutPage>
</template>
<script>
import { fetchApiCredentials } from '@/services/companySettingsService'
export default {
  data: function() {
    return {
      loadedError: false,
      loading: false,
      loadedOk: false,
      accountInfo: null
    }
  },
  components: {},
  methods: {
    fetchAccountInfo() {
      if (this.loading) return
      this.loading = true
      fetchApiCredentials()
        .then(({ data }) => {
          this.accountInfo = data
          this.loadedOk = true
        })
        .catch(() => {
          this.loadedError = true
        })
        .finally(() => {
          this.loading = false
        })
    }
  },

  created() {
    this.fetchAccountInfo()
  }
}
</script>
