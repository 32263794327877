import { createStore } from 'vuex'
import { fetchApiCredentials } from '@/services/companySettingsService'
import { getToken } from '@/services/authService'

export default createStore({
  state: {
    credentials: {
      apiKey: '',
      accountId: '',
      token: null
    }
  },
  actions: {
    async fetchCredentials({ commit }) {
      try {
        const { data } = await fetchApiCredentials()
        commit('SAVE_CREDENTIALS', data)
      } catch (err) {
        console.log(err)
        // Silence
      }
    },
    async fetchToken({ commit }) {
      try {
        const { data } = await getToken()
        commit('SAVE_TOKEN', data)
      } catch (err) {
        console.log(err)
      }
    },
    async saveToken({ commit }, data) {
      console.log('Save token in store', data)
      commit('SAVE_TOKEN', data)
    }
  },
  mutations: {
    SAVE_CREDENTIALS(state, { apiKey, id }) {
      state.apiKey = apiKey
      state.accountId = id
    },
    SAVE_TOKEN(state, data) {
      state.token = data
    }
  },
  modules: {}
})
