<template>
  <LayoutPage>
    <!-- Headers -->
    <div class="mt-2 flex items-center justify-between mb-3">
      <h1 class="text-2xl">{{ `Users` }}</h1>
      <el-button @click="visibleModalInvitation = true" type="primary" class="uppercase">Invite Users</el-button>
    </div>

    <div>
      <template v-if="loadedOk">
        <div class="align-middle inline-block min-w-full w-full">
          <div class="shadow-sm border overflow-hidden sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase">Name</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase">Marketing Role</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase">Support Role</th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-600 uppercase">Status</th>
                  <th scope="col" class="px-2 pr-7 py-3 text-right text-xs font-medium text-gray-600 uppercase">Action</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="(user, userIdx) in users" :key="user.id">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm font-medium text-gray-900">
                      <template v-if="user.status != 'invited'">
                        {{ [user.first_name, user.last_name].join(' ') }} <br />
                        <span class="text-xs text-gray-400">{{ user.email }}</span>
                      </template>
                      <template v-else>{{ user.email }}</template>
                    </div>
                    <div class="text-sm text-gray-500" v-if="user.status != 'invited'">
                      <template>{{ user.email }}</template>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap capitalize text-sm text-gray-800">
                    {{ user.role == 'none' ? 'No Access' : user.role }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap capitalize text-sm text-gray-800">
                    {{ user.support_system_role == 'none' ? 'No Access' : user.support_system_role }}
                  </td>
                  <td class="px-2 py-4 whitespace-nowrap">
                    <span
                      v-if="user.status != 'invited'"
                      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                    >
                      Registered
                    </span>
                    <span
                      v-else
                      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800"
                    >
                      Invited
                    </span>
                  </td>
                  <td class="px-6 pr-10 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <el-dropdown trigger="click" @command="handleUserAction(user, $event, userIdx)">
                      <i class="el-icon-more"></i>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item v-if="user.status == 'invited'" command="mail" icon="el-icon-s-promotion"
                            >Re-send invite email</el-dropdown-item
                          >
                          <el-dropdown-item command="edit" icon="el-icon-edit">Edit User</el-dropdown-item>
                          <el-dropdown-item command="remove" icon="el-icon-delete">Remove User</el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template v-if="loading">
        <div class="my-16 relative" style="min-height: 500px">
          <Spinner />
        </div>
      </template>
      <template v-if="loadedError">
        <CLoadErrorTemp />
      </template>
    </div>

    <!-- Edit User Dialog -->
    <UpdateUserDialog ref="updateUserDialog" @refresh:user-list="fetchList" />

    <el-dialog
      title="Invite Users"
      v-model="visibleModalInvitation"
      width="500"
      :before-close="handleCloseModal"
      @opened="onDialogOpened"
    >
      <InviteUserDialog ref="inviteUserDialogElement" @close:modal="handleCloseModal" @refresh:user-list="fetchList" />
    </el-dialog>
    <template v-if="loadedError">
      <CLoadErrorTemp />
    </template>
  </LayoutPage>
</template>
<script>
import { ref, getCurrentInstance } from 'vue'
import { fetchUserList, deleteUser, resendInviteUser, deleteInvitationUser } from '@/services/userService'
import UpdateUserDialog from '../components/UpdateUserDialog.vue'
import InviteUserDialog from '../components/InviteUserDialog.vue'
export default {
  components: {
    InviteUserDialog,
    UpdateUserDialog
  },
  setup() {
    const updateUserDialog = ref(null)
    const inviteUserDialogElement = ref(null)
    const visibleModalInvitation = ref(false)
    const app = getCurrentInstance()
    const loading = ref(false)
    const loadedError = ref(false)
    const loadedOk = ref(false)
    const prototypes = app.appContext.config.globalProperties
    const isSendingInvitation = ref(false)
    const users = ref([])
    function fetchList() {
      if (loading.value) return
      loading.value = true
      fetchUserList({})
        .then(({ data }) => {
          if (Array.isArray(data)) {
            users.value = data
            loadedOk.value = true
          }
        })
        .catch(() => {
          loadedError.value = true
        })
        .finally(() => {
          loading.value = false
        })
    }
    fetchList()

    function handleUserAction(user, action, userIdx) {
      if (action === 'remove') this.confirmDelete(user, userIdx)
      if (action === 'edit') this.showEditDialog(user, userIdx)
      else if (action === 'mail') this.sendInviteUserAgain(user)
    }

    function handleCloseModal() {
      visibleModalInvitation.value = false
    }
    function toastErrorDelete() {
      prototypes.$alert('Something went wrong when deleting user. Please try again later!', 'Error', {
        confirmButtonText: 'OK'
      })
    }

    function confirmDelete(user, userIdx) {
      prototypes.$confirm('This will permanently delete this user. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = 'Deleting...'
            const apiMethodDeleteUser = user.status === 'registered' ? deleteUser : deleteInvitationUser
            apiMethodDeleteUser(user.id)
              .then(() => {
                prototypes.$message({ type: 'success', message: 'User Removed' })
                users.value.splice(userIdx, 1)
                done()
              })
              .catch(() => {
                toastErrorDelete()
              })
              .finally(() => {
                instance.confirmButtonLoading = false
                instance.confirmButtonText = 'Delete'
              })
          } else {
            done()
          }
        }
      })
    }

    function showEditDialog(user, userIdx) {
      updateUserDialog.value.showDialog(JSON.parse(JSON.stringify(user)))
    }

    function onDialogOpened() {
      if (inviteUserDialogElement.value) inviteUserDialogElement.value.focusInput()
    }

    function sendInviteUserAgain(user) {
      isSendingInvitation.value = true
      resendInviteUser({ userId: user.id })
        .then(() => {
          prototypes.$message({
            message: 'Re-sent invitation email.',
            type: 'success'
          })
        })
        .catch(() => {
          prototypes.$alert('Something went wrong while resending user invitation. Please try again later!', 'Error', {
            confirmButtonText: 'OK'
          })
        })
        .finally(() => {
          isSendingInvitation.value = false
        })
    }

    return {
      loading,
      loadedError,
      loadedOk,
      inviteUserDialogElement,
      visibleModalInvitation,
      handleCloseModal,
      users,
      fetchList,
      confirmDelete,
      showEditDialog,
      onDialogOpened,
      updateUserDialog,
      handleUserAction,
      sendInviteUserAgain,
      isSendingInvitation
    }
  }
}
</script>
