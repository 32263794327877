<template>
  <div class="w-full" v-if="isVerified != null">
    <div class="rounded-md bg-yellow-100 border border-yellow-300 p-4 pb-3" v-if="isVerified == false">
      <div class="flex">
        <div class="ml-3">
          <h3 class="text-sm font-medium text-yellow-800">Integration Pending</h3>
          <div class="mt-2 text-sm text-yellow-600">
            <p>
              The integration is not complete yet, you will need to authorize Growlytics App access in your Facebook Or Google Ad
              account. Reach-out to Growlytics support team to know more.
            </p>
          </div>

          <div class="mt-2 text-left">
            <button
              @click="onVerifyIntegration"
              type="button"
              class="bg-secondary-700 px-3 py-1.5 rounded text-sm font-medium text-white hover:bg-secondary-900"
            >
              Verify Integration
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Is Verified -->
    <div class="rounded-md bg-primary-50 border border-green-800 p-4 pb-3" v-else>
      <div class="flex">
        <div class="ml-3">
          <h3 class="text-sm font-medium text-primary-600">Integration Is Active</h3>
          <div class="mt-2 text-sm text-primary-600">
            <p>
              Growlytics can access to your ad account. If you have changed any settings, you can click on verify to integration
              status again. Reach-out to Growlytics support team if you are facing any issues.
            </p>
          </div>

          <div class="mt-2 text-left">
            <button
              @click="onVerifyIntegration"
              type="button"
              class="bg-secondary-700 px-3 py-1.5 rounded text-sm font-medium text-white hover:bg-secondary-900"
            >
              Verify Integration
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { verifySocialIntegration, fetchProviderDetailItem } from '@/services/channelService'

export default {
  props: {
    channelId: {
      required: false,
      type: String,
      default: null
    }
  },
  data: function() {
    return {
      isVerified: null
    }
  },
  methods: {
    async onVerifyIntegration() {
      try {
        const { data } = await verifySocialIntegration(this.channelId)
        if (data.success) {
          this.$alert(
            '<div class="text-gray-600 mb-2">Verification successful. Growlytics now has access to your ad account.</div>',
            'Verification Successful',
            {
              dangerouslyUseHTMLString: true,
              confirmButtonText: 'OK',
              callback: action => {
                if (this.isVerified === data.success) {
                  // Integration was already verified, Don't do anything.
                } else {
                  location.reload()
                }
              }
            }
          )
        } else {
          this.$alert(
            '<div class="text-secondary-600 mb-2">Looks like you have not authorized Growlytics App to access your ad account. </div><div class="text-gray-500">If you are facing any issues, you can reach-out to Growlytics support team.</div>',
            'Verification Failed',
            {
              dangerouslyUseHTMLString: true,
              confirmButtonText: 'OK'
            }
          )
        }
        console.log('Verify response:', data)
      } catch (err) {
        console.error('Failed to verify social media integration', err)
      }
    }
  },
  async mounted() {
    if (this.channelId) {
      const { data } = await fetchProviderDetailItem('social', this.channelId)
      const result = data[0]
      if (result.settings) {
        this.isVerified = result.settings.isVerified
      }
    }
  }
}
</script>
