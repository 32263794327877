<template>
  <div id="BrandSettingsPage" title="Campaign Settings" :classList="'overflow-hidden'">
    <div class="mt-2 flex items-center justify-between mb-3">
      <h1 class="text-2xl">Brand Settings</h1>
    </div>

    <div class="bg-white pb-0 border rounded-lg overflow-hidden headerSection">
      <!-- Tabs Header -->
      <div class="pt-2 px-5 border-b bg-gray-50 flex tabsContainer">
        <div
          class="mr-4 p-2 px-3 border-b border-b-7 tabItem"
          @click="onTabChange('general')"
          :class="{ activeTabItem: selectedTab == 'general' }"
        >
          General
        </div>
        <div
          class="mr-4 p-2 px-3 border-b border-b-7 tabItem"
          @click="onTabChange('fonts')"
          :class="{ activeTabItem: selectedTab == 'fonts' }"
        >
          Custom Fonts
        </div>
      </div>

      <div>
        <!-- Frequency Capping -->
        <template v-if="selectedTab === 'general'">
          <BrandingComponent v-if="selectedTab === 'general'" />
        </template>

        <!-- Frequency Capping -->
        <template v-if="selectedTab === 'fonts'">
          <CustomFonts />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import BrandingComponent from './components/branding/Branding.vue'
import CustomFonts from './components/customFonts/CustomFonts.vue'
export default {
  components: {
    BrandingComponent,
    CustomFonts
  },
  data() {
    return {
      selectedTab: 'general'
    }
  },
  methods: {
    onTabChange(newTab) {
      this.selectedTab = newTab
      // this.fetchAllData()
    }
  },
  setup() {
    return {}
  }
}
</script>

<style lang="scss">
#BrandSettingsPage {
  .headerSection {
    .tabsContainer {
      .tabItem {
        border-bottom: 3px solid transparent;
        cursor: pointer;
        color: #777;
        font-size: 15px;
        padding-bottom: 13px !important;

        &:hover {
          color: #444;
        }
      }

      .activeTabItem {
        border-color: #1a535c;
        color: #444;
      }
    }
  }
}
</style>
