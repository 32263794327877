<template>
  <component :is="layout">
    <router-view v-slot="slotProps">
      <transition name="fade" mode="out-in">
        <component :is="slotProps.Component"></component>
      </transition>
    </router-view>
  </component>
</template>

<script>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
const defaultLayout = 'default'
export default {
  setup() {
    const { currentRoute } = useRouter()
    const store = useStore()
    if (location.pathname !== '/auth') {
      // Set token from url if present.
      const urlParams = new URLSearchParams(window.location.search)
      const loginToken = urlParams.get('loginToken')
      if (loginToken) {
        store.dispatch('saveToken', loginToken)
      }

      store.dispatch('fetchCredentials')
      store.dispatch('fetchToken')
    }
    const layout = computed(() =>
      currentRoute.value.meta.layout ? `${currentRoute.value.meta.layout || defaultLayout}-layout` : 'default-layout'
    )
    return {
      layout
    }
  }
}
</script>

<style lang="scss"></style>
