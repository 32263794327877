import router from '@/router'
import AuthPage from './AuthPage.vue'

router.addRoute({
  path: '/auth',
  name: 'AuthPage',
  component: AuthPage,
  meta: {
    layout: 'default'
  }
})
