<template>
  <LayoutPage>
    <div class="mt-6 mb-6 grid grid-cols-2 w-full">
      <div>
        <router-link :to="{ name: 'SocialMediaView' }" class="text-gray-600 hover:text-black">
          <i class="text-2xl el-icon-back"></i>
        </router-link>
        <h1 class="text-2xl inline-block ml-2">Create Social Media</h1>
      </div>
    </div>
    <EditForm v-model="formModel" :buttons="formButtons" key="create-social-media" />
  </LayoutPage>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import EditForm from '../components/EditForm.vue'
import { createProviderItem } from '@/services/channelService'
import { ElMessageBox, ElMessage } from 'element-plus'
export default {
  components: {
    EditForm
  },
  setup() {
    const router = useRouter()
    const state = reactive({
      formModel: {},
      formButtons: [
        { label: 'Create Provider', type: 'submit', isLoading: false, attrs: { type: 'primary' }, onClick: () => submitForm() },
        { label: 'Cancel', type: 'cancel', isLoading: false, attrs: {}, onClick: () => router.push({ name: 'SocialMediaView' }) }
      ]
    })
    function populatePayload(payload) {
      payload.settings.isVerified = false
      return payload
    }
    async function submitForm() {
      state.formButtons[0].isLoading = true
      try {
        const result = await createProviderItem(populatePayload(state.formModel))
        if (!result.data.success) {
          ElMessageBox.alert(result.data.message, 'Error', {
            confirmButtonText: 'OK'
          })
        } else {
          ElMessage({ type: 'success', message: 'Provider created.' })
          router.push({ name: 'SocialMediaView' })
        }
      } catch {
        ElMessageBox.alert('Something went wrong when creating social media provider. Please try again later!', 'Error', {
          confirmButtonText: 'OK'
        })
      }
      state.formButtons[0].isLoading = false
    }
    return {
      ...toRefs(state)
    }
  }
}
</script>
