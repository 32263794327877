import router from '@/router'
import MobilePushPage from './MobilePushPage.vue'

router.addRoute({
  path: '/channels/mobilePush',
  name: 'MobilePushPage',
  component: MobilePushPage,
  meta: {
    layout: 'dashboard'
  }
})
