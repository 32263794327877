<template>
  <div id="brandInfoPage" class="max-w-screen-xl m-auto">
    <div class="loader" v-if="fetchingData" v-loading="true"></div>

    <div v-else>
      <!-- Content -->
      <el-row class="mainContent p-6" :gutter="25">
        <el-col :span="12" class="leftSideForm">
          <el-form ref="brandInfoForm" label-position="top" :model="brandInfo" :rules="validationRules" label-width="150px">
            <div>
              <el-form-item label="Brand Name" prop="brand_name">
                <el-input v-model="brandInfo.brand_name" placeholder="Brand Name" maxlength="50" show-word-limit></el-input>
              </el-form-item>
            </div>
            <el-form-item label="Brand Logo - Dark" prop="brand_logo">
              <el-input id="imageWPushInput" v-model="brandInfo.brand_logo" placeholder=""></el-input>
              <div class="hint">Logo shown in preview can be resized later depending on your requirements.</div>
            </el-form-item>

            <el-form-item label="Brand Logo - White" prop="brand_logo_light">
              <el-input id="imageWPushInput" v-model="brandInfo.brand_logo_light" placeholder=""></el-input>
              <div class="hint">Logo shown in preview can be resized later depending on your requirements.</div>
            </el-form-item>
            <el-form-item label="Brand WebSite URL" prop="site_url">
              <el-input v-model="brandInfo.site_url" placeholder="Brand Website URL" maxlength="500" show-word-limit></el-input>
              <div class="hint">
                Just put the domain name of your site like example.com, putting more info may break Growlytics auto link
                generation.
              </div>
            </el-form-item>
            <el-form-item label="Contact Email" prop="contact_email">
              <el-input v-model="brandInfo.contact_email" placeholder="Contact Email" maxlength="500" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="Address" prop="address">
              <el-input
                type="textarea"
                v-model="brandInfo.address"
                :rows="3"
                placeholder="Full Address"
                maxlength="2000"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item label="Currency" prop="currency">
              <el-select v-model="brandInfo.currency" style="width: 100%">
                <el-option v-for="(curr, index) in currencyList" :key="index" :label="curr" :value="curr"> </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="Email Custom Font URL" prop="emailFontUrl">
            <el-input type="textarea" :rows="5" placeholder="https://fonts.googleapis.com/css2?family=MyFonts" v-model="brandInfo.font_settings.url"></el-input>
          </el-form-item>
          <el-form-item label="Email Custom Font URL" prop="emailFontStyle">
            <el-input type="text" placeholder="font-family: 'MyFonts', sans-serif;" v-model="brandInfo.font_settings.style"></el-input>
            <div class="hint">Make sure you are also providing fall-back fonts, incase if email client does not support custom fonts, fall-back fonts will be used.</div>
          </el-form-item> -->
          </el-form>

          <!-- Submit Button -->
          <div class="pt-4">
            <el-button class="w-full" type="primary" size="large" @click="onSaveChanges" :loading="savingData">{{
              saveButtonText
            }}</el-button>
          </div>
        </el-col>

        <!-- Right side email Preview -->
        <el-col :span="12" class="rightSideOfBrandInfo">
          <el-row class="pb-6" :gutter="10">
            <el-col :span="12">
              <!-- Dark - Color Picker -->
              <div class="mt-4 text-center" border>
                <div class="inline-flex items-center bg-gray-200 rounded border overflow-hidden">
                  <div class="px-2 text-sm">Dark Color</div>
                  <div class="bg-white py-0.5 pl-1 pr-2 flex items-center">
                    <el-color-picker
                      ref="colorPickerEle"
                      popper-class="webpush-colorpicker"
                      size="mini"
                      v-model="brandInfo.color_dark"
                      @active-change="onDarkColorChange"
                    />
                    <a class="ml-1 text-sm text-gray-700" @click="openColorPicker">{{ brandInfo.color_dark }}</a>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <!-- Light - Color Picker -->
              <div class="mt-4 text-center" border>
                <div class="inline-flex items-center bg-gray-200 rounded border overflow-hidden">
                  <div class="px-2 text-sm">Light Color</div>
                  <div class="bg-white py-0.5 pl-1 pr-2 flex items-center">
                    <el-color-picker
                      ref="colorPickerEle"
                      popper-class="webpush-colorpicker"
                      size="mini"
                      v-model="brandInfo.color_light"
                      @active-change="onLightColorChange"
                    />
                    <a class="ml-1 text-sm text-gray-700" @click="openColorPicker">{{ brandInfo.color_dark }}</a>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <!-- <div class="previewHeader">Email Preview Based On Brand Details</div> -->
          <div class="mailPreviewContainer">
            <div class="emailPreview">
              <div class="logoContainer">
                <el-image slfit="contain" :src="brandInfo.brand_logo">
                  <template #error>
                    <div class="img-error-slot">
                      <i class="el-icon-picture-outline"></i><br />
                      Dark Logo Not Specified
                    </div>
                  </template>
                </el-image>
              </div>
              <div class="title" :style="{ color: brandInfo.color_dark }">
                Thank you for shopping with {{ brandInfo.brand_name }}.
              </div>
              <div class="description">
                Whether it's an important announcement, new products or services or letting people know about a special promotion.
                Maybe you need to link to something. With Growlytics, you're covered. Happy emailing!
              </div>
              <div class="buttonContainer">
                <el-button type="info" size="small" disabled>Start Shopping</el-button>
              </div>
              <div class="footer p-3 text-center" :style="{ background: brandInfo.color_dark }">
                <div class="pt-2 text-sm" :style="{ color: brandInfo.color_light }">{{ brandInfo.address }}</div>
                <div class="pt-5 text-4xl">
                  <el-image slfit="contain" style="height: 40px;" :src="brandInfo.brand_logo_light">
                    <template #error>
                      <div class="img-error-slot bg-white text-xs p-1">
                        <i class="el-icon-picture-outline"></i><br />
                        Light Logo Not Specified
                      </div>
                    </template>
                  </el-image>
                </div>
                <div class="pt-6 text-xs text-white">
                  No longer want to receive these emails? Unsubscribe.
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import BrandingComponent from './BrandingComponent'
export default BrandingComponent
</script>

<style lang="scss">
#brandInfoPage {
  .loader {
    // border: 1px solid #ececec;
    background: white;
    height: 50vh;
    padding-left: 20px;
    margin-top: 10px;
    border-radius: 5px;
    overflow: hidden;
  }

  .mainContent {
    .leftSideForm {
      .el-form-item {
        &__label {
          margin: 0px !important;
          padding: 0px !important;
          line-height: 30px !important;
          color: #9e9e9e;
          font-size: 12px;
        }
      }

      .hint {
        margin-top: 3px;
        font-size: 11px;
        line-height: 15px;
        color: #8f9fae;
      }
    }

    .rightSideOfBrandInfo {
      padding-top: 15px;
      .previewHeader {
        text-align: center;
        color: #555;
        margin-bottom: 10px;
        font-size: 15px;
      }

      .mailPreviewContainer {
        border-color: rgb(180, 187, 195);
        border-image: initial;
        padding: 16px;
        background: #e8e8e8;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px 0px;

        .emailPreview {
          background: white;
          width: 100%;
          height: auto;
          word-wrap: break-word;

          .logoContainer {
            width: 70%;
            margin: auto;
            padding: 15px;

            .el-image {
              width: 100%;

              .img-error-slot {
                color: #777;

                i {
                  color: #ddd;
                  font-size: 40px;
                }

                width: 100%;
                text-align: center;
                padding: 60px 0px;
              }
            }
          }

          .title {
            width: 100%;
            padding: 10px;
            text-align: center;
            border-top: 2px solid #dfdfdf;
            padding-top: 15px;
            font-size: 16px;
          }

          .description {
            padding: 10px;
            font-size: 12px;
            line-height: 16px;
            text-align: justify;
          }

          .buttonContainer {
            padding: 20px;
            text-align: center;

            .el-button {
              color: #555;
            }
          }

          .footer {
            background: rgb(245, 246, 246);
            padding: 10px;

            &-header {
              font-size: 12px;
              text-align: center;
              color: #777;
            }

            &-logo {
              color: #777;
              font-size: 22px;
              margin-top: 15px;
              text-align: center;
            }

            &-address {
              color: #777;
              font-size: 12px;
              margin-top: 15px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>
