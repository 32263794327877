import { getBrandSettings, saveBrandSettings } from '@/services/brandInfoService'

export default {
  name: 'BrandingComponent',
  props: {
    saveButtonText: {
      required: false,
      default: 'Save Changes'
    }
  },
  data() {
    return {
      fetchingData: true,
      savingData: false,

      brandInfo: {
        brand_name: 'MyBrand',
        site_url: '',
        address: 'Brand Address Goes Here',
        contact_email: 'contact@yourbrand.com',
        currency: '₹',
        brand_logo: '',
        font_settings: {
          url: '',
          style: ''
        },
        color_dark: '#040E3E',
        color_light: '#FF008E',
        brand_logo_light: null
      },

      validationRules: {
        brand_name: [
          {
            required: true,
            message: 'Brand name is required.',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 150,
            message: 'Brand name length should be 3 to 150 characters.',
            trigger: 'blur'
          }
        ],
        site_url: [
          {
            required: true,
            message: 'Site Url is required',
            trigger: 'blur'
          },
          {
            max: 1000,
            message: 'Site Url can not exceed 1000 characters.',
            trigger: 'blur'
          }
        ],
        brand_logo: [
          {
            required: true,
            message: 'Dark brand logo image is required.',
            trigger: 'blur'
          }
        ],
        brand_logo_light: [
          {
            required: true,
            message: 'White brand logo image is required.',
            trigger: 'blur'
          }
        ],
        address: [
          {
            required: true,
            message: 'Address is required.',
            trigger: 'blur'
          },
          {
            max: 2000,
            message: 'Address can not exceed 2000 characters.',
            trigger: 'blur'
          }
        ],
        contact_email: [
          {
            required: true,
            message: 'Contact email is required.',
            trigger: 'blur'
          }
        ],
        currency: [
          {
            required: true,
            message: 'Contact email is required.',
            trigger: 'blur'
          }
        ]
      },

      currencyList: ['₹', '$']
    }
  },

  methods: {
    onImageUpload(obj, prop, img) {
      obj[prop] = img.url
    },

    onDarkColorChange(color) {
      this.brandInfo.color_dark = color
    },

    onLightColorChange(color) {
      this.brandInfo.color_light = color
    },

    async validateForm() {
      return new Promise(resolve => {
        this.$refs.brandInfoForm.validate(valid => {
          const isInvalid = valid === false
          resolve(!isInvalid)
        })
      })
    },

    async onSaveChanges() {
      // Validate Form
      const isValidData = await this.validateForm()
      if (!isValidData) return

      // Make request to save data
      this.savingData = true
      try {
        await saveBrandSettings(this.brandInfo)
        this.successToast('Changes saved successfully.')
        this.$emit('onSaveBrandInfo')
        this.savingData = false
      } catch (e) {
        this.reportError(e)
        this.errorToast('Something went wrong.')
        this.sendingTestNotification = false
        this.savingData = false
      }
    },

    async fetchBrandInfo() {
      this.fetchingData = true
      try {
        const result = await getBrandSettings()
        if (result.data) {
          this.overrideJson(this.brandInfo, result.data)
        }
        this.fetchingData = false
      } catch (e) {
        this.reportError(e)
        this.errorToast('Something went wrong.')
      }
    }
  },

  mounted() {
    this.fetchBrandInfo()
  }
}
