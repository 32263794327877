import session from './session'
import { rootAPI } from './settings'

const getListIntegrations = params => {
  return session.get(
    rootAPI + 'integrations/',
    { params },
    {
      withCredentials: true
    }
  )
}

const getIntegrationByKey = key => {
  return session.get(rootAPI + `integrations/${key}`, {
    withCredentials: true
  })
}

const createIntegration = payload => {
  return session.post(rootAPI + 'integrations/', payload)
}

const updateIntegrationById = (id, payload) => {
  return session.put(rootAPI + `integrations/${id}`, payload)
}

const deleteIntegrationById = (id, payload) => {
  return session.delete(rootAPI + `integrations/${id}`, payload)
}

export { getListIntegrations, getIntegrationByKey, createIntegration, updateIntegrationById, deleteIntegrationById }
