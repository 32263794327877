import { requiredRule, minMaxLengthRule, emailRule, domainRule, isNumberRule } from './utils'
// import { deliveryWebhookMetadata, DNSRecordMetadata, sendingDomainTextMetadata } from './static_metadata'
import { deliveryWebhookMetadata } from './static_metadata'

const emailProviderMetadata = {
  channel_type: 'email',
  fixedHeader: [
    {
      type: 'select',
      key: 'provider_type', // IMPORTANT: Cant change this
      value: 'amazon_ses',
      label: 'Email Provider Type',
      placeholder: 'Please select',
      options: [
        {
          value: 'amazon_ses',
          label: 'AWS SES'
        },
        {
          value: 'sendgrid',
          label: 'Sendgrid'
        },
        {
          value: 'mailgun-email',
          label: 'Mailgun'
        },
        {
          value: 'growlytics-email',
          label: 'Growlytics'
        },
        {
          value: 'pepipost-email',
          label: 'Pepipost - NetCore'
        },
        {
          value: 'smtp',
          label: 'Custom SMTP'
        }
      ],
      rules: [requiredRule('Email Provider Type')]
    },
    {
      type: 'input',
      key: 'provider_name', // Key to send request body to server
      value: '',
      label: 'Name Your Configuration',
      placeholder: 'Give provider name',
      rules: [requiredRule('Name Your Configuration'), minMaxLengthRule('Aws access key', 1, 2000)]
    }
  ],
  dynamicProviders: {
    amazon_ses: {
      fields: [
        {
          type: 'input',
          key: 'accessKey', // Key to send request body to server
          value: '',
          label: 'Access Key',
          placeholder: 'AWS Access Key',
          rules: [requiredRule('Aws access key'), minMaxLengthRule('Aws access key', 1, 2000)]
        },
        {
          type: 'input',
          key: 'secretKey',
          value: '',
          label: 'Secret Key',
          placeholder: 'AWS Secret Key',
          rules: [requiredRule('Aws Secret Key'), minMaxLengthRule('Aws Secret Key', 1, 2000)]
        },
        {
          type: 'select',
          key: 'region',
          value: 'AP-SOUTH-1',
          label: 'Aws Region',
          placeholder: 'Please select',
          options: [
            { value: 'US-EAST-1', label: 'US-EAST-1' },
            { value: 'US-WEST-2', label: 'US-WEST-2' },
            { value: 'EU-WEST-1', label: 'EU-WEST-1' },
            { value: 'AP-EAST-1', label: 'AP-EAST-1' },
            { value: 'AP-SOUTH-1', label: 'AP-SOUTH-1' },
            { value: 'AP-NORTHEAST-1', label: 'AP-NORTHEAST-1' },
            { value: 'AP-NORTHEAST-2', label: 'AP-NORTHEAST-2' },
            { value: 'AP-NORTHEAST-3', label: 'AP-NORTHEAST-3' },
            { value: 'AP-SOUTHEAST-1', label: 'AP-SOUTHEAST-1' },
            { value: 'AP-SOUTHEAST-2', label: 'AP-SOUTHEAST-2' }
          ],
          rules: [requiredRule('Region')]
        },
        {
          type: 'input',
          key: 'from',
          value: '',
          label: 'From Email Address',
          placeholder: 'From Email Address',
          rules: [requiredRule('From email'), emailRule('From email'), minMaxLengthRule('From email', 1, 1000)]
        },
        deliveryWebhookMetadata({
          attrs: {
            emailProviderType: 'aws-ses'
          }
        })
      ]
    },
    // 'growlytics-email': {
    //   fields: [
    //     sendingDomainTextMetadata,
    //     {
    //       type: 'input',
    //       key: 'domain',
    //       value: '',
    //       label: 'Enter Sending Domain',
    //       placeholder: 'Enter domain for ending emails',
    //       rules: [requiredRule('Email domain'), minMaxLengthRule('Email domain', 1, 1000), domainRule('Email Domain')]
    //     },
    //     {
    //       type: 'input',
    //       key: 'fromAddress',
    //       value: '',
    //       label: 'From Email Address',
    //       placeholder: 'From Email Address',
    //       rules: [requiredRule('From email'), emailRule('From email'), minMaxLengthRule('From email', 1, 1000)]
    //     },
    //     DNSRecordMetadata
    //     // deliveryWebhookMetadata({
    //     //   attrs: {
    //     //     emailProviderType: 'growlytics-email'
    //     //   }
    //     // })
    //   ]
    // },
    'growlytics-email': {
      fields: [
        {
          type: 'input',
          key: 'apikey',
          value: '',
          label: 'Api Key',
          placeholder: 'Api Key',
          rules: [requiredRule('Api key'), minMaxLengthRule('Api key', 1, 5000)]
        },
        {
          type: 'input',
          key: 'from',
          value: '',
          label: 'From Email Address',
          placeholder: 'From Email Address',
          rules: [requiredRule('From email'), emailRule('From email'), minMaxLengthRule('From email', 1, 1000)]
        }
      ]
    },
    sendgrid: {
      fields: [
        {
          type: 'input',
          key: 'apiKey',
          value: '',
          label: 'Api Key',
          placeholder: 'Api Key',
          rules: [requiredRule('Api key'), minMaxLengthRule('Api key', 1, 5000)]
        },
        {
          type: 'input',
          key: 'fromAddress',
          value: '',
          label: 'From Email Address',
          placeholder: 'From Email Address',
          rules: [requiredRule('From email'), emailRule('From email'), minMaxLengthRule('From email', 1, 1000)]
        },
        deliveryWebhookMetadata({
          attrs: {
            emailProviderType: 'sendgrid'
          }
        })
      ]
    },
    'pepipost-email': {
      fields: [
        {
          type: 'input',
          key: 'apiKey',
          value: '',
          label: 'Api Key',
          placeholder: 'Api Key',
          rules: [requiredRule('Api key'), minMaxLengthRule('Api key', 1, 5000)]
        },
        {
          type: 'input',
          key: 'fromAddress',
          value: '',
          label: 'From Email Address',
          placeholder: 'From Email Address',
          rules: [requiredRule('From email'), emailRule('From email'), minMaxLengthRule('From email', 1, 1000)]
        },
        deliveryWebhookMetadata({
          attrs: {
            emailProviderType: 'pepipost-email'
          }
        })
      ]
    },
    'mailgun-email': {
      fields: [
        {
          type: 'input',
          key: 'apiKey',
          value: '',
          label: 'Api Key',
          placeholder: 'Api Key',
          rules: [requiredRule('Api key'), minMaxLengthRule('Api key', 1, 5000)]
        },
        {
          type: 'input',
          key: 'fromAddress',
          value: '',
          label: 'From Email Address',
          placeholder: 'From Email Address',
          rules: [requiredRule('From email'), emailRule('From email'), minMaxLengthRule('From email', 1, 1000)]
        },
        {
          type: 'input',
          key: 'sendingDomain',
          value: '',
          label: 'Sending Domain',
          placeholder: 'Mailgun Sending Domain',
          rules: [requiredRule('Domain'), domainRule('Domain'), minMaxLengthRule('Domain', 1, 1000)]
        },

        deliveryWebhookMetadata({
          attrs: {
            emailProviderType: 'mailgun-email'
          }
        })
      ]
    },
    smtp: {
      fields: [
        {
          type: 'input',
          key: 'host', // Key to send request body to server
          value: '',
          label: 'Host',
          placeholder: 'Host',
          rules: [requiredRule('Host'), minMaxLengthRule('Host', 1, 2000)]
        },
        {
          type: 'input',
          key: 'port',
          value: '',
          label: 'Port',
          placeholder: 'Port',
          rules: [requiredRule('Port'), isNumberRule('Port'), minMaxLengthRule('Port', 1, 7)]
        },
        {
          type: 'input',
          key: 'username',
          value: '',
          label: 'User Name',
          placeholder: 'User Name',
          rules: [requiredRule('User Name'), minMaxLengthRule('User Name', 1, 2000)]
        },
        {
          type: 'input',
          key: 'password',
          value: '',
          label: 'Password',
          placeholder: 'Password',
          rules: [requiredRule('Aws Secret Key'), minMaxLengthRule('Aws Secret Key', 1, 2000)]
        },

        {
          type: 'input',
          key: 'fromAddress',
          value: '',
          label: 'From Email Address',
          placeholder: 'From Email Address',
          rules: [requiredRule('From email'), emailRule('From email'), minMaxLengthRule('From email', 1, 1000)]
        },
        deliveryWebhookMetadata({
          attrs: {
            emailProviderType: 'smtp'
          }
        })
      ]
    }
  }
}
export default emailProviderMetadata
