const flattenObj = ob => {
  if (typeof ob !== 'object') return
  // The object which contains the
  // final result
  var result = {}

  // loop through the object "ob"
  for (const i in ob) {
    // We check the type of the i using
    // typeof() function and recursively
    // call the function again
    if (typeof ob[i] === 'object') {
      const temp = flattenObj(ob[i])
      for (const j in temp) {
        // Store temp in result
        result[j] = temp[j]
      }
    } else {
      result[i] = ob[i]
    }
  }
  return result
}

export { flattenObj }
