<template>
  <LayoutPage>
    <template v-if="loaded">
      <div id="IntegrationDetailPage" class="p-8">
        <!-- Header -->
        <div class="shadow overflow-hidden overflow-hidden rounded-lg" :gutter="0">
          <!-- Breadcrumb -->
          <div class="p-3 bg-gray-50 border-b border-b-gray-200">
            <ol role="list" class="flex items-center space-x-4">
              <li>
                <div class="flex items-center">
                  <router-link
                    :to="{ name: 'IntegrationListView' }"
                    class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    Integrations
                  </router-link>
                </div>
              </li>
              <li>
                <div class="flex items-center">
                  <!-- Heroicon name: solid/chevron-right -->
                  <svg
                    class="flex-shrink-0 h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <a href="#" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700" aria-current="page">{{
                    integration.title
                  }}</a>
                </div>
              </li>
            </ol>
          </div>

          <div class="flex p-3 bg-white">
            <div class="flex-wrap">
              <div class="rounded overflow-hidden border border-gray-200 p-2 h-24 w-24 mr-4">
                <img class="rounded overflow-hidden w-full" :src="integration.icon" />
                <!-- <img
                  class="w-full"
                  src="https://s3-us-west-1.amazonaws.com/partnerpage.prod/media/contact_images/632da94a-6f28-4da4-bbbe-3e093858b5a9/a55bc55d-564c-48ba-bdfd-b4ca9aebdecd.png?AWSAccessKeyId=AKIA5CCSK7ARDXEJBSMU&Signature=YdqLop6%2FAzYbpYoeSInVRW%2Fq2Bw%3D&Expires=1630940666"
                /> -->
              </div>
            </div>
            <div class="flex-1">
              <!-- <div class="flex-1 text-xs text-gray-500 capitalize">{{ integration.type + ' - ' + integration.subType }}</div> -->
              <div class="flex-1 font-semibold text-xl pb-1">{{ integration.title }}</div>
              <div class="flex-1 text-gray-500 leading-5" style="font-size: 13px">{{ integration.description.long }}</div>
            </div>
          </div>
        </div>

        <div class="flex mt-6">
          <div class="flex-1">
            <div class="mr-5 shadow overflow-hidden overflow-hidden rounded-lg bg-white" :gutter="0">
              <el-form :rules="rules" label-position="top" ref="formElement" :model="form" label-width="250px" class="">
                <div class="p-3 bg-gray-50 border-b border-b-gray-200">Integration Settings</div>
                <div class="bg-white w-full px-5 pt-2">
                  <template v-for="formItem in integrationDetailForm" :key="formItem.key">
                    <el-form-item :label="formItem.label" :prop="formItem.label">
                      <el-input v-model="form[formItem.key]" :placeholder="formItem.placeholder" />
                    </el-form-item>
                  </template>
                </div>
                <div class="px-5 py-3 bg-white border-t border-b-gray-200">
                  <el-button type="primary" @click="saveChanges" :loading="isFormSaving" v-if="integration.installed"
                    >Update Settings</el-button
                  >
                  <el-button type="primary" @click="createNew" :loading="isFormSaving" v-else>Integrate</el-button>

                  <!-- Delete -->
                  <template v-if="integration.installed">
                    <el-button type="danger" :loading="isDeleting" @click="deleteThis">Remove Integration</el-button>
                  </template>
                </div>
              </el-form>
            </div>
          </div>

          <!-- Use-cases List -->
          <div class="flex-wrap">
            <div style="width: 375px">
              <div class="shadow overflow-hidden overflow-hidden rounded-lg" :gutter="0">
                <div class="p-3 bg-gray-50 border-b border-b-gray-200">Use Cases</div>
                <div class="bg-white w-full">
                  <ul role="list" class="divide-y divide-gray-200">
                    <li class="p-4" v-for="(usecase, index) in integration.usecases" :key="index">
                      <div class="text-sm font-medium text-gray-600">{{ usecase.title }}</div>
                      <div class="text-xs text-gray-500">{{ usecase.description }}</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="loading">
      <div class="my-16 relative" style="min-height: 500px">
        <Spinner />
      </div>
    </template>
  </LayoutPage>
</template>

<script>
import { getCurrentInstance, onMounted, ref } from 'vue'
import {
  getIntegrationByKey,
  createIntegration,
  updateIntegrationById,
  deleteIntegrationById
} from '@/services/integrationService'
import { integrationDetailForm } from '@/metadata/integrations'
import { useRoute } from 'vue-router'
export default {
  setup() {
    const route = useRoute()
    const app = getCurrentInstance()
    const globalProperties = app.appContext.config.globalProperties
    const integration = ref(null)
    const loading = ref(false)
    const loaded = ref(false)
    const loadError = ref(false)
    const rules = {}
    const form = ref({})
    const isFormSaving = ref(false)
    const isDeleting = ref(false)
    onMounted(() => readIntegration())
    function renderForm() {
      var inSettings = {}
      var inSettingRes = integration.value?.settings
      if (inSettingRes) {
        try {
          inSettings = JSON.parse(inSettingRes)
        } catch {
          console.log('JSON error')
        }
      }
      form.value = Object.keys(integrationDetailForm).reduce((obj, key) => {
        obj[key] = inSettings[key]
        return obj
      }, {})
    }
    async function readIntegration() {
      try {
        loading.value = true
        const { data } = await getIntegrationByKey(route.params.integrationId)
        integration.value = data
        renderForm()
        loaded.value = true
      } catch {
        loadError.value = true
      } finally {
        loading.value = false
      }
    }

    async function createNew() {
      try {
        isFormSaving.value = true
        const payload = {
          key: integration.value.key,
          settings: Object.keys(integrationDetailForm).reduce((obj, key) => {
            obj[key] = form.value[key]
            return obj
          }, {})
        }
        await createIntegration(payload)
        globalProperties.$message({ type: 'success', message: 'Integration has been added.' })
        console.log('create call')
        readIntegration()
      } catch (err) {
        console.log(err)
        let errorMsg = 'Something went wrong when adding the integration. Please contact support!'
        if (err.response && err.response.status === 406) {
          errorMsg = err.response.data
        }
        globalProperties.$alert(errorMsg, 'Error', {
          confirmButtonText: 'OK'
        })
      } finally {
        isFormSaving.value = false
      }
    }

    async function saveChanges() {
      try {
        isFormSaving.value = true
        const payload = Object.keys(integrationDetailForm).reduce((obj, key) => {
          obj[key] = form.value[key]
          return obj
        }, {})
        await updateIntegrationById(integration.value.id, payload)
        globalProperties.$message({ type: 'success', message: 'Integration updated.' })
      } catch (err) {
        let errorMsg = 'Something went wrong when updating the integration. Please contact support!'
        if (err.response && err.response.status === 406) {
          errorMsg = err.response.data
        }
        globalProperties.$alert(errorMsg, 'Error', {
          confirmButtonText: 'OK'
        })
      } finally {
        isFormSaving.value = false
      }
    }

    function deleteThis() {
      globalProperties
        .$confirm('This will permanently delete the integration. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })
        .then(async () => {
          try {
            isDeleting.value = true
            await deleteIntegrationById(integration.value.id)
            globalProperties.$message({ type: 'success', message: 'Integration removed.' })
            readIntegration()
          } catch (err) {
            console.log(err)
            let errorMsg = 'Something went wrong when deleting the integration. Please contact support!'
            if (err.response && err.response.status === 406) {
              errorMsg = err.response.data
            }
            globalProperties.$alert(errorMsg, 'Error', {
              confirmButtonText: 'OK'
            })
          } finally {
            isDeleting.value = false
          }
        })
        .catch(() => {})
    }

    return {
      integrationDetailForm,
      form,
      integration,
      loading,
      loaded,
      loadError,
      rules,
      isFormSaving,
      createNew,
      saveChanges,
      isDeleting,
      deleteThis
    }
  }
}
</script>
