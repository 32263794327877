import router from '@/router'
import UserListPage from './views/UserListPage.vue'

router.addRoute({
  path: '/users',
  name: 'UserListPage',
  component: UserListPage,
  meta: {
    layout: 'dashboard'
  }
})
