<template>
  <LayoutPage title="Campaign Settings" :classList="'bg-white rounded-lg border'">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="Frequency Capping" name="FrequencyCapping">
        <FrequencyCapping v-if="activeTab === 'FrequencyCapping'" />
      </el-tab-pane>
      <!-- <el-tab-pane label="DND Hours" name="DNDHours">
        <DNDHours v-if="activeTab === 'DNDHours'" />
      </el-tab-pane> -->
      <el-tab-pane label="Campaign Throttling" name="third">
        <div class="h-60 text-center w-full text-gray-500 text-2xl mt-40">
          Under Development
        </div>
      </el-tab-pane>
    </el-tabs>
  </LayoutPage>
</template>
<script>
import FrequencyCapping from './components/FrequencyCapping.vue'
export default {
  components: {
    FrequencyCapping
  },
  data() {
    return {
      activeTab: 'FrequencyCapping'
    }
  },
  methods: {}
}
</script>
