<template>
  <ChannelStandardListPage
    detailRouteName="SocialMediaEditView"
    createRouteName="SocialMediaCreateView"
    channelId="social"
    page-title="Social Media Channels"
    textNodata="No social Chanels Configured Yet"
  />
</template>
<script>
import ChannelStandardListPage from '@/core/components/ChannelStandardListPage.vue'
export default {
  components: {
    ChannelStandardListPage
  },
  setup() {}
}
</script>
