import countryCodes from '@/utils/CountryCodes.json'

export const isValidDomain = require('is-valid-domain')

export const requiredRule = function(fieldDisplayName) {
  return {
    required: true,
    message: `${fieldDisplayName} is required`,
    trigger: 'change'
  }
}
export const minMaxLengthRule = function(fieldDisplayName, min, max) {
  return {
    required: true,
    message: `${fieldDisplayName} must be in range of ${min} to ${max} characters.`,
    trigger: 'change'
  }
}
export const emailRule = function() {
  return {
    type: 'email',
    message: 'Please input correct email address',
    trigger: ['blur', 'change']
  }
}
export const domainRule = function(fieldDisplayname) {
  return (rule, value, callback) => {
    if (isValidDomain(value)) {
      callback()
    } else {
      callback(new Error('Please input the valid ' + fieldDisplayname))
    }
  }
}
export const isNumberRule = function(fieldDisplayname) {
  return (rule, value, callback) => {
    if (!isNaN(value)) {
      callback()
    } else {
      callback(new Error('Please input the valid ' + fieldDisplayname))
    }
  }
}

const isValidCountryCode = async value => {
  if (value === '') {
    return false
  } else {
    if (value.length <= 3) {
      const countryCodeList = await countryCodes.map(x =>
        x.dial_code ? x.dial_code.replaceAll('+', '').replace(/\s+/g, '') : ''
      )
      if (countryCodeList.includes(value)) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }
}

export const countryCodeRule = function(fieldDisplayname) {
  return (rule, value, callback) => {
    if (isValidCountryCode(value)) {
      callback()
    } else {
      callback(new Error('Please input the valid ' + fieldDisplayname))
    }
  }
}
