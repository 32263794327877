import { fetchWebPushVapidProviderDetails } from '@/services/channelService'

import IosPopup from './pushEditPopups/iosPushPopup.vue'
import FcmPopup from './pushEditPopups/fcmPushPopup.vue'

export default {
  name: 'mobilePushPage',
  components: {
    IosPopup,
    FcmPopup
  },
  data() {
    return {
      providerType: 'ios',

      channelSettings: null,

      loadingData: false,
      loadedOk: false,
      loadedError: false
    }
  },

  methods: {
    onTabChange(newProviderType) {
      this.providerType = newProviderType
      if (this.providerType === 'ios') {
        this.fetchIosSettings()
      } else {
        this.fetchFcmSettings()
      }
    },

    async fetchIosSettings() {
      this.loadingData = true
      this.loadedOk = false
      this.loadedError = false
      try {
        const response = await fetchWebPushVapidProviderDetails({ providerType: 'mobilePush' })
        const data = response.data

        this.loadedOk = true
        this.loadedError = false
        this.channelSettings = null

        if (Array.isArray(data)) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].provider_type === 'apns') {
              const result = data[i]
              delete result.company_id
              this.channelSettings = result
              break
            }
          }
        }
      } catch (e) {
        console.error(e)
        this.loadedError = true
      } finally {
        this.loadingData = false
      }
    },

    async fetchFcmSettings() {
      this.loadingData = true
      this.loadedOk = false
      this.loadedError = false
      try {
        const response = await fetchWebPushVapidProviderDetails({ providerType: 'mobilePush' })
        const data = response.data

        this.loadedOk = true
        this.loadedError = false
        this.channelSettings = null

        if (Array.isArray(data)) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].provider_type === 'fcm') {
              const result = data[i]
              delete result.company_id
              this.channelSettings = result
            }
          }
        }
      } catch (e) {
        console.error(e)
        this.loadedError = true
      } finally {
        this.loadingData = false
      }
    },

    async showIosEditDialog() {
      const channelId = this.channelSettings ? this.channelSettings.id : null
      const settings = this.channelSettings ? this.channelSettings.settings : null

      this.$refs.IosPopup.showDialog(channelId, settings)
    },

    async showAndroidEditDialog() {
      const channelId = this.channelSettings ? this.channelSettings.id : null
      const settings = this.channelSettings ? this.channelSettings.settings : null

      this.$refs.FcmPopup.showDialog(channelId, settings)
    }
  },

  mounted() {
    this.onTabChange('ios')
  }
}
