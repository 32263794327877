import router from '@/router'
import ApiCredentialsPage from './ApiCredentialsPage.vue'

router.addRoute({
  path: '/integration',
  name: 'ApiCredentialsPage',
  component: ApiCredentialsPage,
  meta: {
    layout: 'dashboard'
  }
})
