<template>
  <div class="flex flex-col border border-solid border-gray-200 p-4">
    <div class="font-semibold mb-3">
      <div class="text-primary mb-1 leading-5">Delivery Webhook URL</div>
      <div class="text-gray-600 leading-5">
        <template v-if="providerType && apiKey">
          {{ `https://cts.growlytics.in/${providerType}/production/${apiKey}` }}
        </template>
        <template v-else>
          -
        </template>
      </div>
    </div>
    <p class="text-gray-800 text-sm">Use above webhook url to receive delivery reports.</p>
  </div>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  props: {
    emailProviderType: {
      type: String
    }
  },
  setup(props) {
    const store = useStore()
    const providerType = computed(() => (props.emailProviderType ? props.emailProviderType.replace('_', '-') : ''))
    const apiKey = computed(() => (store.state?.apiKey ? store.state.apiKey : ''))
    return {
      providerType,
      apiKey
    }
  }
}
</script>
