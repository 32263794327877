import router from '@/router'
import WhatsAppDetailPage from './views/WhatsAppDetailPage.vue'
import WhatsAppListPage from './views/WhatsAppListPage.vue'

router.addRoute({
  path: '/channels/whatsApp',
  name: 'WhatsAppListPage',
  component: WhatsAppListPage,
  meta: {
    layout: 'dashboard'
  }
})

router.addRoute({
  path: '/channels/whatsApp/:id',
  name: 'WhatsAppDetailPage',
  component: WhatsAppDetailPage,
  meta: {
    layout: 'dashboard'
  }
})
