<template>
  <el-dialog append-to-body custom-class="iosPushPopup" title="iOS Push Credentials" v-model="dialogVisible" width="800px">
    <div class="py-5 px-10">
      <el-form :model="form" :rules="formRules" ref="iosPushForm" label-position="top">
        <!-- Key File -->
        <div class="mb-3">
          <!-- Label -->
          <div class="mb-2" style="font-size: 14px; color: #606266;">Key File - iPhone (.p8)</div>

          <!-- If File Uploaded -->
          <template v-if="form.keyFile">
            <span class="text-gray-700 text-base pr-3">{{ form.keyFileName }}</span>
            <el-button type="primary" @click="$refs.iosPushKeyFileUploadInput.click()" size="mini" round plain>
              Change Key File
            </el-button>
          </template>
          <!-- If No File Uploaded -->
          <template v-else>
            <el-button type="primary" @click="$refs.iosPushKeyFileUploadInput.click()" size="mini" round plain>
              Upload Key File
            </el-button>
          </template>

          <!-- Error Message -->
          <div v-if="keyFileError" class="text-red-500 text-sm mt-2">{{ keyFileError }}</div>

          <!-- File Uploader Input -->
          <input
            ref="iosPushKeyFileUploadInput"
            @change="onUploadedKeyFile"
            class="hidden"
            type="file"
            multiple="false"
            accept=".p8"
          />
        </div>

        <!-- Key Id -->
        <el-form-item label="Team ID" prop="teamId">
          <el-input v-model="form.teamId"></el-input>
        </el-form-item>

        <!-- Team Id -->
        <el-form-item label="Key ID" prop="keyId">
          <el-input v-model="form.keyId"></el-input>
        </el-form-item>

        <!-- Bundle Id -->
        <el-form-item label="Bundle ID" prop="bundleId">
          <el-input v-model="form.bundleId"></el-input>
        </el-form-item>

        <!-- Bundle Ipad Id -->
        <el-form-item label="Bundle ID for iPad (in case of different bundle IDs)" prop="bundleIdIpad">
          <el-input v-model="form.bundleIdIpad"></el-input>
        </el-form-item>

        <!-- Development Mode -->
        <el-form-item label="Push Mode" prop="devType">
          <el-radio size="small" v-model="form.isProduction" :label="true" border>Production</el-radio>
          <el-radio size="small" v-model="form.isProduction" :label="false" border>Development</el-radio>
        </el-form-item>
      </el-form>
    </div>

    <template #footer>
      <el-button type="primary" @click="saveChanges">Save Changes</el-button>
      <el-button @click="dialogVisible = false">Cancel</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { saveChannelProviderInfo, createProviderItem } from '@/services/channelService'

export default {
  data() {
    return {
      dialogVisible: false,
      savingChanges: false,

      existingChannelId: null,

      form: null,
      formTemplate: {
        keyFileName: '',
        keyFile: '',
        keyId: '',
        teamId: '',
        bundleId: '',
        bundleIdIpad: '',
        isProduction: true
      },

      formRules: {
        teamId: [
          { required: true, message: 'Team ID is required.', trigger: 'blur' },
          { min: 1, max: 500, message: 'Team ID can not exceed 500 characters.', trigger: 'blur' }
        ],
        keyId: [
          { required: true, message: 'Key ID is required.', trigger: 'blur' },
          { min: 1, max: 500, message: 'Key ID can not exceed 500 characters.', trigger: 'blur' }
        ],
        bundleId: [
          { required: true, message: 'Bundle ID is required.', trigger: 'blur' },
          { min: 1, max: 500, message: 'Bundle ID can not exceed 500 characters.', trigger: 'blur' }
        ],
        bundleIdIpad: [
          { required: false },
          { min: 0, max: 500, message: 'Bundle ID can not exceed 500 characters.', trigger: 'blur' }
        ]
      },

      // Key File Additional Fields.
      keyFileError: null
    }
  },

  methods: {
    showDialog(existingChannelId, inputData) {
      this.existingChannelId = existingChannelId || null

      if (inputData) {
        this.form = this.overrideJson(JSON.parse(JSON.stringify(this.formTemplate)), JSON.parse(JSON.stringify(inputData)))
      } else {
        this.form = JSON.parse(JSON.stringify(this.formTemplate))
      }

      this.dialogVisible = true
    },

    onUploadedKeyFile(e) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = async e => {
        this.form.keyFile = e.target.result
        this.form.keyFileName = file.name
        this.keyFileError = null
      }
    },

    async validateForm(form) {
      // Valiate Key File.
      this.keyFileError = null
      if (!this.form.keyFile) {
        this.keyFileError = 'Key File is required.'
      }

      // Validate Form.
      const isFormValid = await new Promise(resolve => {
        form.validate(valid => {
          const isInvalid = valid === false
          resolve(!isInvalid)
        })
      })

      if (isFormValid && !this.keyFileError) {
        return true
      }
      return false
    },

    async saveChanges() {
      // Validate Form
      const isValid = await this.validateForm(this.$refs.iosPushForm)
      if (!isValid) return null

      // Save Changes.
      try {
        const apiToCall = this.existingChannelId ? saveChannelProviderInfo : createProviderItem

        const apiParams = {
          id: this.existingChannelId ? this.existingChannelId : undefined,
          settings: this.form,
          channel_type: 'mobilePush',
          provider_name: 'iOs Push Credentials',
          provider_type: 'apns'
        }

        const response = await apiToCall(apiParams)

        if (response.data.success) {
          this.$message({ message: 'Settings saved successfully.', type: 'success' })
          this.$emit('settingsChanged')
          this.dialogVisible = false
        } else {
          this.$alert('Something went wrong when saving changes. Please try again later!', 'Error', {
            confirmButtonText: 'OK'
          })
        }
      } catch (err) {
        this.$alert('Something went wrong when saving changes. Please try again later!', 'Error', {
          confirmButtonText: 'OK'
        })
      } finally {
        this.busySubmitting = false
      }
    }
  },

  mounted() {
    // Set Form.
    this.form = JSON.parse(JSON.stringify(this.formTemplate))
  }
}
</script>

<style>
.iosPushPopup {
}
</style>
