import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles/index.scss'
import './assets/styles/element-variables.scss'
import initializeCore from './core'
import ElementPlus from 'element-plus'
/**
  Import all modules
**/
import './modules'
/**
  End import all modules
**/

// Add Mixin.
import GeneraLMixin from './mixins/commonMixin'

function startApp() {
  const app = createApp(App)
  app.use(store)
  app.mixin(GeneraLMixin)
  app.use(router)
  initializeCore(app)
  app.use(ElementPlus)
  app.mount('#app')
}
startApp()
