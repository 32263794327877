import { stringIsWebhookURL } from '@/utils/regex'
import { getSettings, saveSettings } from '@/services/companySettingsService'
export default {
  data() {
    var valiateUrl = (rule, value, callback) => {
      if (!value) {
        callback(new Error('Please provide valid url'))
      } else {
        if (!stringIsWebhookURL(value)) {
          callback(new Error('Please input the valid webhook url (It must start with http or https).'))
          return
        }
        callback()
      }
    }
    return {
      // WebPush Form
      loadingData: true,
      savingData: false,
      loadedOk: true,
      id: '',
      form: {
        couponWebhook: 'https://google.com'
      },
      couponFormRules: {
        couponWebhook: [
          {
            required: true,
            message: 'Coupon Webhook Url is required',
            trigger: 'blur'
          },
          {
            max: 1000,
            message: 'Coupon Webhook Url can not exceed 1000 characters.',
            trigger: 'blur'
          },
          { validator: valiateUrl, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    async fetchCouponSettings() {
      try {
        this.loadingData = true
        const { data } = await getSettings(['couponWebhook'])
        if (data.length > 0) {
          this.form.couponWebhook = data[0].value
        }
        this.loadingData = false
        this.loadedOk = true
      } catch (err) {
        console.error(err)
        this.loadingData = false
        this.loadedOk = false
      }
    },

    async validateForm() {
      return new Promise(resolve => {
        this.$refs.couponForm.validate(valid => {
          const isInvalid = valid === false
          resolve(!isInvalid)
        })
      })
    },

    async saveCouponSettings(e) {
      try {
        // Validate settings.
        const isValid = await this.validateForm()
        if (!isValid) return

        this.savingData = true
        await saveSettings(this.form, 'coupon')
        this.$message({ message: 'Changes saved.', type: 'success' })
        this.savingData = false
      } catch (err) {
        console.error(err)
        this.savingData = false
      }
      return false
    }
  },
  mounted() {
    this.fetchCouponSettings()
  }
}
