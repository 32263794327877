<template>
  <div class="max-w-screen-xl m-auto p-6" id="mobilePushProviderChannelPage">
    <!-- Header -->
    <div class="flex items-center justify-between">
      <h1 class="text-2xl">Mobile Push Channel</h1>
      <el-link href="https://docs.growlytics.in/channels/web-push-notifications" target="_blank" type="primary" class="text-sm">
        <i class="el-icon-notebook-2"></i>
        Mobile Push integration guide
      </el-link>
    </div>

    <!-- Tabs -->
    <div class="mt-3 bg-white pb-0 border rounded-lg overflow-hidden headerSection">
      <!-- Tabs Header -->
      <div class="pt-2 px-5 border-b bg-gray-50 flex tabsContainer">
        <div
          class="mr-4 p-2 px-3 border-b border-b-7 tabItem"
          @click="onTabChange('ios')"
          :class="{ activeTabItem: providerType == 'ios' }"
        >
          iOS
        </div>

        <div
          class="mr-4 p-2 px-3 border-b border-b-7 tabItem"
          @click="onTabChange('fcm')"
          :class="{ activeTabItem: providerType == 'fcm' }"
        >
          Android FCM
        </div>
      </div>

      <template v-if="loadedOk">
        <!-- Tab Contents -->
        <div class="p-5">
          <!-- iOS Tab -->
          <div class="iosSection p-3" v-if="providerType == 'ios'">
            <div class="relative" v-if="channelSettings">
              <div class="absolute top-0 right-0" style="z-index: 1000">
                <el-button @click="showIosEditDialog" type="primary" plain size="small"> Edit iOs Credentials </el-button>
              </div>
              <el-row>
                <el-col :span="12">
                  <div>
                    <div class="label">Key File - iPhone (.p8)</div>
                    <div class="valueContainer">{{ channelSettings.settings.keyFileName }}</div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div>
                    <div class="label">Key ID</div>
                    <div class="valueContainer">{{ channelSettings.settings.keyId }}</div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div>
                    <div class="label">Team ID</div>
                    <div class="valueContainer">{{ channelSettings.settings.teamId }}</div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div>
                    <div class="label">Push Mode</div>
                    <div class="valueContainer">{{ channelSettings.settings.isProduction ? 'Production' : 'Development' }}</div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div>
                    <div class="label">Bundle ID</div>
                    <div class="valueContainer">{{ channelSettings.settings.bundleId }}</div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div>
                    <div class="label">Bundle ID for iPad (in case of different bundle IDs)</div>
                    <div class="valueContainer">{{ channelSettings.settings.bundleIdIpad }}</div>
                  </div>
                </el-col>
              </el-row>
            </div>

            <!-- Not Configured Yet -->
            <div class="text-center py-20" v-if="!channelSettings">
              <div>
                iOS Push Credentials is not configured yet.
              </div>
              <div class="mt-5">
                <el-button type="primary" round @click="showIosEditDialog">Configure iOS Push</el-button>
              </div>
            </div>
          </div>

          <!-- Android Tab -->
          <div class="iosSection p-3" v-if="providerType == 'fcm'">
            <div class="relative" v-if="channelSettings">
              <div class="absolute top-0 right-0" style="z-index: 1000">
                <el-button @click="showAndroidEditDialog" type="primary" plain size="small"> Edit Fcm Credentials </el-button>
              </div>

              <el-row>
                <el-col :span="24">
                  <div>
                    <div class="label">Key File</div>
                    <div class="valueContainer">{{ channelSettings.settings.filename }}</div>
                  </div>
                </el-col>
                <el-col :span="24">
                  <div>
                    <div class="label">Client Email</div>
                    <div class="valueContainer">{{ channelSettings.settings.settings.client_email }}</div>
                  </div>
                </el-col>
                <el-col :span="24">
                  <div>
                    <div class="label">Client ID</div>
                    <div class="valueContainer">{{ channelSettings.settings.settings.client_id }}</div>
                  </div>
                </el-col>
              </el-row>
            </div>

            <!-- Not Configured Yet -->
            <div class="text-center py-20" v-if="!channelSettings">
              <div>
                FCM Push Credentials is not configured yet.
              </div>
              <div class="mt-5">
                <el-button type="primary" round @click="showAndroidEditDialog">Configure FCM Push</el-button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="loadedError">
        <CLoadErrorTemp />
      </template>
    </div>

    <IosPopup ref="IosPopup" @settingsChanged="fetchIosSettings"></IosPopup>
    <FcmPopup ref="FcmPopup" @settingsChanged="fetchFcmSettings"></FcmPopup>
  </div>
</template>

<style lang="scss" src="./MobilePushPage.scss"></style>

<script>
import MobilePushPage from './MobilePushPage.js'
export default MobilePushPage
</script>
