<template>
  <div class="el-loading-spinner">
    <svg class="circular" viewBox="25 25 50 50">
      <circle class="path" cx="50" cy="50" r="20" fill="none"></circle>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'Spinner'
}
</script>
