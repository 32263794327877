<template>
  <el-dialog
    custom-class="addFontDialog"
    width="670px"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    destroy-on-close
    :title="isEdit ? 'Edit font' : 'Add a custom font'"
    v-model="dialogVisible"
  >
    <el-form class="p-3" ref="formRef" :model="form" :rules="formRules" label-position="top">
      <el-form-item label="Font Name" :label-width="formLabelWidth" prop="name">
        <el-input placeholder="Enter font name" v-model="form.name" autocomplete="off" />
      </el-form-item>

      <div class="mt-10 mb-6" v-if="!isEdit">
        <el-upload ref="fontFileUploader" action="#" :auto-upload="false" :show-file-list="false" :on-change="onFileUpload">
          <template #trigger>
            <el-button type="primary" size="mini">Upload Font File</el-button>
          </template>
          <span class="pl-4 text-primary">{{ uploadedFile ? uploadedFile.name : 'No file selected' }}</span>
          <template #tip>
            <div class="text-xs text-gray-500 mt-2">
              Accepted file types: .woff, .woff2, .ttf, .otf, .eot and .svg
            </div>
          </template>
        </el-upload>
      </div>
      <div v-else class="mt-7 mb-5">
        <div>Font File - {{ form.files[0].format.toUpperCase() }} File</div>
        <a target="_blank" class="text-sm block text-primary-700 mt-1" :href="form.files[0].url">Download Font File</a>
        <div class="text-xs block text-gray-400 mt-1">Font file can not be changed</div>
      </div>

      <el-form-item label="Fallback font" :label-width="formLabelWidth" prop="fallback">
        <el-select v-model="form.fallback" placeholder="Select fallback font" class="w-full">
          <el-option v-for="font in fallbackFonts" :key="font" :label="font" :value="font" />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="">
        <el-button class="w-40" type="primary" @click="saveFonts" :loading="inProgress">
          {{ isEdit ? 'Update font' : 'Add Font' }}
        </el-button>
        <el-button @click="handleCloseDialog" type="danger" plain>Cancel</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
// import { ref, reactive, toRefs, watch, computed } from 'vue'
import { uploadFontImage } from '@/services/brandInfoService'
import { fallbackFonts } from './utils'

export default {
  components: {},
  props: {},
  data() {
    return {
      formLabelWidth: '80px',
      dialogVisible: false,
      uploadedFile: null,
      inProgress: false,

      isEdit: false,
      editIndex: -1,
      fallbackFonts: fallbackFonts,

      form: null,
      formRules: {
        name: { required: true, message: 'Please input Font Name', trigger: 'blur' },
        fallback: { required: true, message: 'Please input Fallback Font', trigger: 'change' }
      },
      formTemplate: {
        name: '',
        files: [{ format: '', url: '' }],
        fallback: '',
        type: 'upload'
      }
    }
  },

  methods: {
    onFileUpload(file) {
      this.uploadedFile = file.raw
      this.$refs.fontFileUploader.clearFiles()
    },

    async validateForm() {
      try {
        await this.$refs.formRef.validate()
        return true
      } catch (err) {
        return false
      }
    },

    async processUploadedFileForSubmit() {
      // Validate uploaded file.
      if (!this.uploadedFile) {
        this.warningToast('Please upload font file.')
        return null
      } else {
        // Validate uploaded file.
        const fileName = this.uploadedFile.name
        const extension = fileName.substr(fileName.lastIndexOf('.') + 1, fileName.length)
        console.log('file is', extension, this.uploadedFile)
        if (['woff', 'woff2', 'svg', 'ttf', 'otf'].indexOf(extension) < 0) {
          this.$msgbox({
            title: 'Invalid Font File',
            message: 'Uploaded font file is invalid. Valid font file types are: .woff, .woff2, .ttf, .otf, .eot and .svg',
            confirmButtonText: 'Okay'
          })
          return null
        }
      }

      // Upload file to s3 and generate url.
      const formData = new FormData()
      formData.append('image', this.uploadedFile)
      const response = await uploadFontImage(formData)
      console.log('Upload File', response.data)

      const url = response.data.url
      let format = response.data.format

      // Check file exptensionFormat, based on for
      if (format === 'ttf') {
        format = 'truetype'
      } else if (format === 'otf') {
        format = 'opentype'
      } else if (format === 'eot') {
        format = 'embedded-opentype'
      } else {
        // For rest, font format will be same is file format.
      }

      return { url, format }
    },

    async saveFonts() {
      try {
        // Validate Form
        const isValid = await this.validateForm()
        if (!isValid) {
          return
        }

        // Validate & Process File To Be Uploaded
        if (!this.isEdit) {
          const fileUploadResult = await this.processUploadedFileForSubmit()
          if (fileUploadResult == null) {
            // Invalid file return
            return
          }
          this.form.files = [
            {
              format: fileUploadResult.format,
              url: fileUploadResult.url
            }
          ]
        }

        // If form is invalid, will go to invalid form.
        if (this.isEdit) {
          const payload = { form: this.form, type: 'edit', index: this.editIndex }
          this.$emit('onSaveChanges', payload)
        } else {
          const payload = { form: this.form, type: 'add', index: 0 }
          this.$emit('onSaveChanges', payload)
        }
        this.dialogVisible = false
      } catch (err) {
        console.error(err)
        this.errorToast('Something went wrong. Please contact support!')
      }
    },

    async showDialog(form, isEdit, editIndex) {
      if (form) this.form = form
      else this.form = JSON.parse(JSON.stringify(this.formTemplate))

      this.isEdit = isEdit
      this.editIndex = editIndex

      this.dialogVisible = true
    },

    handleCloseDialog(done) {
      this.dialogVisible = false
    }
  },
  emits: ['onSaveChanges'],

  mounted() {}
}
</script>

<style lang="scss">
.addFontDialog {
  border-radius: 7px;
  overflow: hidden;

  .el-dialog__header {
    border-bottom: 1px solid #ddd;
    background: #fafafa;
  }
  .el-dialog__footer {
    border-top: 1px solid #ddd;
    background: #fafafa;
    margin-bottom: -10px;
  }
}
</style>
