import { requiredRule, minMaxLengthRule, countryCodeRule } from './utils'
import { deliveryWebhookMetadata } from './static_metadata'

const smsMetadata = {
  channel_type: 'sms',
  fixedHeader: [
    {
      type: 'select',
      key: 'provider_type', // IMPORTANT: Cant change this
      value: 'your-bulk-sms',
      label: 'SMS Provider Type',
      placeholder: 'Please select',
      options: [
        {
          value: 'gupshup-sms',
          label: 'Gupshup SMS'
        },
        {
          value: 'kaleyra-sms',
          label: 'Kaleyra SMS'
        },
        {
          value: 'msg91',
          label: 'MSG91 SMS'
        },
        {
          value: 'value-first-v2-sms',
          label: 'Value First SMS'
        },
        {
          value: 'value-first-sms',
          label: 'Value First SMS (Deprecated)'
        },
        {
          value: 'vasudev-sms',
          label: 'Vasudev SMS'
        },
        {
          value: 'trust-signal-sms',
          label: 'TrustSignal SMS'
        },
        {
          value: 'your-bulk-sms',
          label: 'Your Bulk SMS'
        },
        {
          value: 'sms-alert-sms',
          label: 'SMS Alert'
        },
        {
          value: 'nimbus-sms',
          label: 'Nimbus SMS'
        },
        {
          value: 'kenscio-sms',
          label: 'Kenscio SMS'
        }
      ],
      rules: [requiredRule('Email Provider Type')]
    },
    {
      type: 'input',
      key: 'provider_name', // Key to send request body to server
      value: '',
      label: 'Name Your Configuration',
      placeholder: 'Give provider name',
      rules: [requiredRule('Name Your Configuration'), minMaxLengthRule('Aws access key', 1, 2000)]
    }
  ],

  dynamicProviders: {
    'your-bulk-sms': {
      fields: [
        {
          type: 'input',
          key: 'apiKey',
          value: '',
          label: 'Api Key',
          placeholder: 'Api Key',
          rules: [requiredRule('Api key'), minMaxLengthRule('Api key', 1, 5000)]
        },
        {
          type: 'input',
          key: 'sender',
          value: '',
          label: 'Sender',
          placeholder: 'Sender',
          rules: [requiredRule('Sender ID'), minMaxLengthRule('Sender ID', 1, 100)]
        },
        {
          type: 'input',
          key: 'country',
          value: '',
          label: 'Country',
          placeholder: 'Country',
          rules: [requiredRule('Country Code'), minMaxLengthRule('Country Code', 1, 1000), countryCodeRule('Country code')]
        },
        deliveryWebhookMetadata({
          attrs: {
            emailProviderType: 'your-bulk-sms'
          }
        })
      ]
    },
    'kaleyra-sms': {
      fields: [
        {
          type: 'input',
          key: 'apiDomain',
          value: '',
          label: 'API Domain',
          placeholder: 'API Domain',
          rules: [requiredRule('API Domain'), minMaxLengthRule('Api key', 1, 5000)]
        },
        {
          type: 'input',
          key: 'apiKey',
          value: '',
          label: 'Api Key',
          placeholder: 'Api Key',
          rules: [requiredRule('Api key'), minMaxLengthRule('Api key', 1, 5000)]
        },
        {
          type: 'input',
          key: 'senderId',
          value: '',
          label: 'Sender ID',
          placeholder: 'Enter Sender ID',
          rules: [requiredRule('Sender ID'), minMaxLengthRule('Sender ID', 1, 100)]
        },
        {
          type: 'input',
          key: 'sid',
          value: '',
          label: 'SID',
          placeholder: 'Enter SID',
          rules: [requiredRule('SID'), minMaxLengthRule('SID', 1, 1000)]
        }
      ]
    },

    'sms-alert-sms': {
      fields: [
        {
          type: 'input',
          key: 'apiKey',
          value: '',
          label: 'Api key',
          placeholder: 'Api key',
          rules: [requiredRule('Api key'), minMaxLengthRule('Api key', 1, 5000)]
        },
        {
          type: 'input',
          key: 'senderId',
          value: '',
          label: 'Sender Id',
          placeholder: 'Sender Id',
          rules: [requiredRule('Sender Id'), minMaxLengthRule('Sender Id', 1, 5000)]
        },
        {
          type: 'input',
          key: 'entityId',
          value: '',
          label: 'DLT Entity ID',
          placeholder: 'Enter DLT Entity ID',
          rules: [requiredRule('DLT Entity ID'), minMaxLengthRule('DLT Entity ID', 1, 100)]
        },
        deliveryWebhookMetadata({
          attrs: {
            emailProviderType: 'sms-alert-sms'
          }
        })
      ]
    },
    'value-first-sms': {
      fields: [
        {
          type: 'input',
          key: 'username',
          value: '',
          label: 'Username',
          placeholder: 'Enter your value first username',
          rules: [requiredRule('Username'), minMaxLengthRule('Username', 1, 5000)]
        },
        {
          type: 'input',
          key: 'password',
          value: '',
          label: 'Password',
          placeholder: 'Enter your value first password',
          rules: [requiredRule('Password'), minMaxLengthRule('Password', 1, 5000)],
          attrs: {
            showPassword: true
          }
        },
        {
          type: 'input',
          key: 'senderId',
          value: '',
          label: 'Sender Id',
          placeholder: 'Sender Id',
          rules: [requiredRule('Sender Id'), minMaxLengthRule('Sender Id', 1, 5000)]
        },
        {
          type: 'input',
          key: 'entityId',
          value: '',
          label: 'DLT Entity ID',
          placeholder: 'Enter DLT Entity ID',
          rules: [requiredRule('DLT Entity ID'), minMaxLengthRule('DLT Entity ID', 1, 100)]
        }
      ]
    },
    'value-first-v2-sms': {
      fields: [
        {
          type: 'input',
          key: 'username',
          value: '',
          label: 'Username',
          placeholder: 'Enter your value first username',
          rules: [requiredRule('Username'), minMaxLengthRule('Username', 1, 5000)]
        },
        {
          type: 'input',
          key: 'password',
          value: '',
          label: 'Password',
          placeholder: 'Enter your value first password',
          rules: [requiredRule('Password'), minMaxLengthRule('Password', 1, 5000)],
          attrs: {
            showPassword: true
          }
        },
        {
          type: 'input',
          key: 'senderId',
          value: '',
          label: 'Sender Id',
          placeholder: 'Sender Id',
          rules: [requiredRule('Sender Id'), minMaxLengthRule('Sender Id', 1, 5000)]
        },
        {
          type: 'input',
          key: 'entityId',
          value: '',
          label: 'DLT Entity ID',
          placeholder: 'Enter DLT Entity ID',
          rules: [requiredRule('DLT Entity ID'), minMaxLengthRule('DLT Entity ID', 1, 100)]
        }
      ]
    },
    'gupshup-sms': {
      fields: [
        {
          type: 'input',
          key: 'userid',
          value: '',
          label: 'User ID',
          placeholder: 'User ID',
          rules: [requiredRule('User ID'), minMaxLengthRule('User ID', 1, 5000)]
        },
        {
          type: 'input',
          key: 'password',
          value: '',
          label: 'Password',
          placeholder: 'Password',
          rules: [requiredRule('Password'), minMaxLengthRule('Password', 1, 5000)],
          attrs: {
            showPassword: true
          }
        },
        {
          type: 'input',
          key: 'senderId',
          value: '',
          label: 'Sender Id',
          placeholder: '6 Characters Sender Id',
          rules: [requiredRule('Sender Id'), minMaxLengthRule('Sender Id', 1, 5000)]
        },
        {
          type: 'input',
          key: 'entityId',
          value: '',
          label: 'DLT Entity ID',
          placeholder: 'Enter DLT Entity ID',
          rules: [requiredRule('DLT Entity ID'), minMaxLengthRule('DLT Entity ID', 1, 100)]
        },
        deliveryWebhookMetadata({
          attrs: {
            emailProviderType: 'gupshup-sms'
          }
        })
      ]
    },
    'trust-signal-sms': {
      fields: [
        {
          type: 'input',
          key: 'apiKey',
          value: '',
          label: 'Api key',
          placeholder: 'Api key',
          rules: [requiredRule('Api key'), minMaxLengthRule('Api key', 1, 5000)]
        },
        {
          type: 'input',
          key: 'senderId',
          value: '',
          label: 'Sender Id',
          placeholder: 'Sender Id',
          rules: [requiredRule('Sender Id'), minMaxLengthRule('Sender Id', 1, 5000)]
        },
        {
          type: 'select',
          key: 'route',
          value: 'transactional',
          label: 'Route',
          placeholder: 'Route',
          rules: [requiredRule('Route')],
          options: [
            { value: 'transactional', label: 'Transactional' },
            { value: 'promotional', label: 'Promotional' },
            { value: 'otp', label: 'OTP' }
          ]
        },

        deliveryWebhookMetadata({
          attrs: {
            emailProviderType: 'trust-signal-sms'
          }
        })
      ]
    },
    'vasudev-sms': {
      fields: [
        {
          type: 'input',
          key: 'user',
          value: '',
          label: 'User',
          placeholder: 'User',
          rules: [requiredRule('User'), minMaxLengthRule('User', 1, 5000)]
        },
        {
          type: 'input',
          key: 'password',
          value: '',
          label: 'Password',
          placeholder: 'Password',
          rules: [requiredRule('Password'), minMaxLengthRule('Password', 1, 5000)],
          attrs: {
            showPassword: true
          }
        },
        {
          type: 'input',
          key: 'sender',
          value: '',
          label: 'Sender',
          placeholder: 'Sender',
          rules: [requiredRule('Sender ID'), minMaxLengthRule('Sender ID', 1, 100)]
        },
        {
          type: 'input',
          key: 'dltSenderId',
          value: '',
          label: 'DLT Entity ID',
          placeholder: 'DLT Entity ID',
          rules: [requiredRule('DLT Entity ID'), minMaxLengthRule('DLT Entity ID', 1, 100)]
        },
        deliveryWebhookMetadata({
          attrs: {
            emailProviderType: 'vasudev-sms'
          }
        })
      ]
    },
    'nimbus-sms': {
      fields: [
        {
          type: 'input',
          key: 'userId',
          value: '',
          label: 'User ID',
          placeholder: 'Enter User ID',
          rules: [requiredRule('User ID'), minMaxLengthRule('User ID', 1, 5000)]
        },
        {
          type: 'input',
          key: 'password',
          value: '',
          label: 'Password',
          placeholder: 'Enter your value first password',
          rules: [requiredRule('Password'), minMaxLengthRule('Password', 1, 5000)],
          attrs: {
            showPassword: true
          }
        },
        {
          type: 'input',
          key: 'senderId',
          value: '',
          label: 'Sender Id',
          placeholder: 'Sender Id',
          rules: [requiredRule('Sender Id'), minMaxLengthRule('Sender Id', 1, 5000)]
        },
        {
          type: 'input',
          key: 'entityId',
          value: '',
          label: 'DLT Entity ID',
          placeholder: 'Enter DLT Entity ID',
          rules: [requiredRule('DLT Entity ID'), minMaxLengthRule('DLT Entity ID', 1, 100)]
        }
      ]
    },
    'kenscio-sms': {
      fields: [
        {
          type: 'input',
          key: 'username',
          value: '',
          label: 'Username',
          placeholder: 'User',
          rules: [requiredRule('Username'), minMaxLengthRule('Username', 1, 5000)]
        },
        {
          type: 'input',
          key: 'apiKey',
          value: '',
          label: 'Api key',
          placeholder: 'Api key',
          rules: [requiredRule('Api key'), minMaxLengthRule('Api key', 1, 5000)]
        },
        {
          type: 'input',
          key: 'senderId',
          value: '',
          label: 'Sender Id',
          placeholder: 'Sender Id',
          rules: [requiredRule('Sender Id'), minMaxLengthRule('Sender Id', 1, 5000)]
        },
        {
          type: 'input',
          key: 'entityId',
          value: '',
          label: 'DLT Entity ID',
          placeholder: 'Enter DLT Entity ID',
          rules: [requiredRule('DLT Entity ID'), minMaxLengthRule('DLT Entity ID', 1, 100)]
        },
        deliveryWebhookMetadata({
          attrs: {
            emailProviderType: 'kenscio-sms'
          }
        })
      ]
    },
    msg91: {
      fields: [
        {
          type: 'input',
          key: 'apiKey',
          value: '',
          label: 'Api key',
          placeholder: 'Api key',
          rules: [requiredRule('Api key'), minMaxLengthRule('Api key', 1, 5000)]
        },
        {
          type: 'input',
          key: 'senderId',
          value: '',
          label: 'Sender Id',
          placeholder: 'Sender Id',
          rules: [requiredRule('Sender Id'), minMaxLengthRule('Sender Id', 1, 5000)]
        },
        {
          type: 'input',
          key: 'entityId',
          value: '',
          label: 'DLT Entity ID',
          placeholder: 'Enter DLT Entity ID',
          rules: [requiredRule('DLT Entity ID'), minMaxLengthRule('DLT Entity ID', 1, 100)]
        },
        deliveryWebhookMetadata({
          attrs: {
            emailProviderType: 'msg91-sms'
          }
        })
      ]
    }
  }
}

export default smsMetadata
