<template>
  <LayoutPage>
    <div class="mt-2 flex items-center justify-between mb-2">
      <h1 class="text-2xl">{{ `Email Channels` }}</h1>
      <router-link :to="{ name: 'EmailDetailPage', params: { id: 'create' } }">
        <el-button type="primary"> Add Provider </el-button>
      </router-link>
    </div>
    <div class="" style="min-height: 500px" v-loading="loading">
      <template v-if="loadedOk">
        <div class="align-middle inline-block min-w-full w-full">
          <div class="shadow-sm border overflow-hidden sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Email Provider
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th scope="col" class="text-right pr-8 text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200" v-if="tableData.length">
                <tr v-for="(row, indexx) in tableData" :key="indexx">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-500">{{ row.provider_name }}</div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-500">{{ providerTypeDisplayNames[row.provider_type] }}</div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <span
                      v-if="!row.is_archived"
                      class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                    >
                      Active
                    </span>
                    <span v-else class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                      Archived
                    </span>
                  </td>
                  <td class="pr-12 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <el-dropdown trigger="click" @command="handleAction(indexx, $event)">
                      <i class="el-icon-more"></i>
                      <template #dropdown>
                        <el-dropdown-menu trigger="click">
                          <el-dropdown-item command="edit">Edit</el-dropdown-item>
                          <el-dropdown-item v-if="!row.is_archived" command="archive">Archive</el-dropdown-item>
                          <el-dropdown-item v-if="row.is_archived" command="unarchive">Activate</el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="py-10 px-8 bg-white border-t text-gray-600 text-center" v-if="!tableData.length">
              No Email Chanels Configured Yet
            </div>
          </div>
        </div>
      </template>
      <template v-if="loadedError">
        <CLoadErrorTemp />
      </template>
    </div>
  </LayoutPage>
</template>

<script>
import { fetchWebPushVapidProviderDetails, deleteProviderItem, unarchiveProviderItem } from '@/services/channelService'
export default {
  data() {
    return {
      loading: false,
      loadedOk: false,
      loadedError: false,
      tableData: []
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    handleAction(index, action) {
      const provider = this.tableData[index]
      console.log('take action now', provider, index, action)
      if (action === 'edit') {
        this.$router.push({ name: 'EmailDetailPage', params: { id: provider.id } })
      } else if (action === 'archive') {
        this.onDelete(index, provider)
      } else if (action === 'unarchive') {
        this.onUnDelete(index, provider)
      }
    },
    fetchList() {
      if (this.loading) {
        return
      }
      this.loading = true
      fetchWebPushVapidProviderDetails({ providerType: 'email' })
        .then(({ data }) => {
          this.tableData = data
          this.loadedOk = true
        })
        .catch(() => {
          this.loadedError = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    onDelete(index, provider) {
      this.$confirm('Are you sure you want to delete this provider?', 'Warning', {
        confirmButtonText: 'Yes, Archive',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(({ data }) => {
          deleteProviderItem('email', provider.id)
            .then(() => {
              this.$message({ type: 'success', message: 'Provider Archived!' })
              this.$nextTick(() => this.fetchList())
            })
            .catch(err => {
              console.log(err)
              this.$alert('Something went wrong when deleting email provider. Please try again later!', 'Error', {
                confirmButtonText: 'OK'
              })
            })
        })
        .catch(() => {})
    },
    onUnDelete(index, provider) {
      this.$confirm('Are you sure you want to activate this provider?', 'Warning', {
        confirmButtonText: 'Yes, Restore',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(({ data }) => {
          unarchiveProviderItem('email', provider.id)
            .then(() => {
              this.$message({ type: 'success', message: 'Provider Archived!' })
              this.$nextTick(() => this.fetchList())
            })
            .catch(err => {
              console.log(err)
              this.$alert('Something went wrong when deleting email provider. Please try again later!', 'Error', {
                confirmButtonText: 'OK'
              })
            })
        })
        .catch(() => {})
    }
  }
}
</script>
