<template>
  <div style="min-height:500px;" v-loading="loading" class="m-4">
    <div v-show="loaded">
      <div class="-mt-3 mb-4 text-right">
        <el-button type="primary" @click="saveChanges" :loading="savingBusy">Save changes</el-button>
      </div>
      <div class="border border-gray-200 overflow-hidden">
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column prop="name" label="CHANNELS">
            <template #default="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column prop="name" label="MESSAGES PER DAY" width="250">
            <template #default="scope">
              <el-input @blur="onBlurInput" type="number" required v-model="scope.row.day" />
            </template>
          </el-table-column>
          <el-table-column prop="address" label="MESSAGES PER WEEK" width="250">
            <template #default="scope">
              <el-input @blur="onBlurInput" type="number" required v-model="scope.row.week" />
            </template>
          </el-table-column>
          <el-table-column prop="address" label="MESSAGES PER MONTH" width="250">
            <template #default="scope">
              <el-input @blur="onBlurInput" type="number" required v-model="scope.row.month" />
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from 'vue'
import { getCampaignFrequencyCapping, saveCampaignFrequencyCapping } from '@/services/companySettingsService'
import { defaultFrequencyCapping, getFrequencyCappingNameFromKey } from '@/metadata/campaign_settings'
import { ElMessageBox, ElMessage } from 'element-plus'
export default {
  setup() {
    const state = reactive({
      loading: false,
      loaded: false,
      savingBusy: false,
      frequencyData: convertFrequencyToTableData(defaultFrequencyCapping),
      tableData: []
    })
    onMounted(() => {
      fetchData()
    })
    function convertFrequencyToTableData(frequencyData) {
      return Object.keys(frequencyData).reduce((arr, key) => {
        arr.push({
          name: getFrequencyCappingNameFromKey(key),
          key: key,
          ...frequencyData[key]
        })
        return arr
      }, [])
    }
    function tableDataToFrequency() {
      return state.tableData.reduce((obj, row) => {
        obj[row.key] = {
          day: parseInt(row.day) || 0,
          week: parseInt(row.week) || 0,
          month: parseInt(row.month) || 0
        }
        return obj
      }, {})
    }
    async function fetchData() {
      try {
        state.loading = true
        const res = await getCampaignFrequencyCapping(['campaign-frequency-capping'])
        const frequencyData = res.data
        state.tableData = convertFrequencyToTableData(frequencyData)
      } catch (_) {
      } finally {
        state.loading = false
        state.loaded = true
      }
    }
    async function saveChanges() {
      const payload = tableDataToFrequency()
      try {
        state.savingBusy = true
        await saveCampaignFrequencyCapping(payload)
        ElMessage({
          showClose: true,
          type: 'success',
          message: 'Changes Saved.'
        })
      } catch (_) {
        ElMessageBox.alert("Something's not right! Please contact support.", 'Error', {
          confirmButtonText: 'OK'
        })
      } finally {
        state.savingBusy = false
      }
    }
    function onBlurInput(evt) {
      if (!evt.target.value) {
        evt.target.value = 0
      }
    }
    return {
      ...toRefs(state),
      saveChanges,
      onBlurInput
    }
  }
}
</script>
