const stringIsImageURL = url => {
  try {
    const isImage = url.match(/\.(jpeg|jpg|gif|png|ico)$/) != null
    return isImage
  } catch (e) {
    return false
  }
}

const stringIsWebhookURL = url => {
  try {
    const regex = new RegExp(
      '^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$'
    )
    const isImage = url.match(regex) != null
    return isImage
  } catch (e) {
    return false
  }
}

export { stringIsImageURL, stringIsWebhookURL }
