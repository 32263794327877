<template>
  <div class="max-w-screen-xl m-auto" :class="classList" v-bind="$attrs">
    <!-- Headers -->
    <div class="p-4 flex items-center justify-between" v-if="title">
      <h1 class="text-2xl font-medium">{{ title }}</h1>
    </div>
    <slot />
  </div>
</template>
<script>
export default {
  name: 'LayoutPage',
  props: {
    title: String,
    classList: String
  }
}
</script>
