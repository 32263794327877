<template>
  <LayoutPage id="createEmailChannelPage">
    <!-- Header -->
    <div class="mt-6 mb-6 grid grid-cols-2 w-full">
      <div>
        <router-link :to="{ name: 'EmailPage' }" class="text-gray-600 hover:text-black">
          <i class="text-2xl el-icon-back"></i>
        </router-link>
        <h1 class="text-2xl inline-block ml-2">
          {{ isCreatePage ? `Add Email Provider` : `Edit Email Provider` }}
        </h1>
      </div>
    </div>
    <div class="" style="min-height: 500px" v-loading="loading">
      <template v-if="loaded">
        <template v-if="errorLoad">
          <div>{{ `Something goes wrong` }}</div>
        </template>
        <template v-else>
          <div class="align-middle inline-block w-full">
            <CProviderForm v-model="formData" :buttons="formButtons" :metadata="providerMetadata" />
          </div>
        </template>
      </template>
    </div>

    <el-dialog
      title=""
      v-model="showProgressGrowlyticsValidation"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :destroy-on-close="true"
    >
      <div>
        <template v-if="busyValidatingEmailGrowlytics">
          <div class="my-8">
            <div class="el-loading-spinner" style="position: relative;">
              <svg class="circular" style="width: 200px; height: 200px;" viewBox="25 25 50 50">
                <circle class="path" cx="50" cy="50" r="20" fill="none"></circle>
              </svg>
            </div>
          </div>
          <div class="max-w-xl m-auto leading-10 text-gray-500 text-center text-lg">
            VERIFYING DOMAIN
            <br />
            THIS MAY TAKE FEW SECONDS
          </div>
        </template>
        <template v-else-if="failedValidatingEmailGrowlytics.length">
          <FailedGrowlyticsValidationLayer
            :errors="failedValidatingEmailGrowlytics"
            @click:try-again="showProgressGrowlyticsValidation = false"
          />
        </template>
        <template v-else-if="successValidatingEmailGrowlytics">
          <SuccessGrowlyticsValidationLayer @click:continue="onClickContinueValidationLayer" />
        </template>
      </div>
    </el-dialog>
  </LayoutPage>
</template>
<script>
import { ref, computed, watch, getCurrentInstance, onMounted } from 'vue'
import {
  fetchProviderDetailItem,
  createProviderItem,
  validateElasticEmail,
  saveChannelProviderInfo
} from '@/services/channelService'
import FailedGrowlyticsValidationLayer from '../components/FailedGrowlyticsValidationLayer.vue'
import SuccessGrowlyticsValidationLayer from '../components/SuccessGrowlyticsValidationLayer.vue'
import { emailProviderMetadata } from '@/metadata'
import { useRouter, useRoute } from 'vue-router'
import cloneDeep from 'lodash/cloneDeep'
import { ElMessageBox } from 'element-plus'
export default {
  components: {
    FailedGrowlyticsValidationLayer,
    SuccessGrowlyticsValidationLayer
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const app = getCurrentInstance()
    const prototypes = app.appContext.config.globalProperties
    const loaded = ref(false)
    const loading = ref(false)
    const errorLoad = ref(false)
    const formData = ref({})
    const showProgressGrowlyticsValidation = ref(false)
    const busyValidatingEmailGrowlytics = ref(false)
    const failedValidatingEmailGrowlytics = ref(false)
    const successValidatingEmailGrowlytics = ref(false)
    const providerMetadata = ref(cloneDeep(emailProviderMetadata))
    const standardButtons = ref([
      { label: 'Create Provider', type: 'submit', isLoading: false, attrs: { type: 'primary' }, onClick: () => submitForm() },
      { label: 'Cancel', type: 'cancel', isLoading: false, attrs: {}, onClick: () => router.push({ name: 'EmailPage' }) }
    ])
    const saveButtons = ref([
      { label: 'Save Provider', type: 'submit', isLoading: false, attrs: { type: 'primary' }, onClick: () => saveChangeEmail() },
      { label: 'Cancel', type: 'cancel', isLoading: false, attrs: {}, onClick: () => router.push({ name: 'EmailPage' }) }
    ])
    const growlyticButtons = ref([
      {
        label: 'Verify & Save',
        type: 'submit',
        isLoading: false,
        attrs: { type: 'success' },
        onClick: () => verifySaveGrowlytics()
      }
    ])
    const formButtons = computed(() => {
      // if (formData.value.provider_type === 'growlytics-email') {
      //   return growlyticButtons.value
      // } else {
      //   return isCreatePage.value ? standardButtons.value : saveButtons.value
      // }
      return isCreatePage.value ? standardButtons.value : saveButtons.value
    })
    const isCreatePage = computed(() => route.params.id === 'create')
    function submitForm() {
      if (isCreatePage.value) {
        createNewEmail()
      } else {
        saveChangeEmail()
      }
    }
    async function createNewEmail() {
      standardButtons.value[0].isLoading = true
      try {
        const result = await createProviderItem(formData.value)
        if (!result.data.success) {
          ElMessageBox.alert(result.data.message, 'Failure.', {
            confirmButtonText: 'OK'
          })
        } else {
          prototypes.$message({ type: 'success', message: 'Provider created.' })
          router.push({ name: 'EmailPage' })
        }
      } catch {
        prototypes.$alert('Something went wrong when creating email provider. Please try again later!', 'Error', {
          confirmButtonText: 'OK'
        })
      }
      standardButtons.value[0].isLoading = false
    }
    async function saveChangeEmail() {
      saveButtons.value[0].isLoading = true
      try {
        const result = await saveChannelProviderInfo({ id: route.params.id, ...formData.value })

        if (!result.data.success) {
          ElMessageBox.alert(result.data.message, 'Failure.', {
            confirmButtonText: 'OK'
          })
        } else {
          prototypes.$message({ type: 'success', message: 'Provider updated.' })
          router.push({ name: 'EmailPage' })
        }
      } catch {
        prototypes.$alert('Something went wrong when saving email provider. Please try again later!', 'Error', {
          confirmButtonText: 'OK'
        })
      }
      saveButtons.value[0].isLoading = true
    }
    watch(showProgressGrowlyticsValidation, newVal => {
      if (!newVal) {
        busyValidatingEmailGrowlytics.value = false
        failedValidatingEmailGrowlytics.value = []
        successValidatingEmailGrowlytics.value = false
      }
    })
    async function verifySaveGrowlytics() {
      busyValidatingEmailGrowlytics.value = true
      showProgressGrowlyticsValidation.value = true
      try {
        await validateElasticEmail(formData.value.provider_name, formData.value.settings.domain)
        successValidatingEmailGrowlytics.value = true
      } catch (error) {
        var errs = ['Something went wrong']
        const errStatus = error?.response?.status
        if (errStatus === 412) {
          errs = ['Domain Verification Failed']
        } else if (errStatus === 500) {
          errs = ['Domain Verification Failed - APIKey Expired']
        } else {
          errs = [
            'Domain Verification Failed - This domain is associated with another Account and cannot be added to this Account.'
          ]
        }
        if (error.response) {
          if (error.response.data) {
            const errorData = error.response.data
            if (Array.isArray(errorData)) errs = errorData
            else if (typeof errorData === 'string') errs = [errorData]
          }
        }
        failedValidatingEmailGrowlytics.value = errs
      }
      busyValidatingEmailGrowlytics.value = false
    }
    async function initializeData() {
      loading.value = true
      try {
        const { data } = await fetchProviderDetailItem('email', route.params.id)
        const result = data[0]
        if (result.settings.is_verified) {
          // Disabled button
          growlyticButtons.value[0].label = 'Verified'
          growlyticButtons.value[0].attrs = { class: 'pointer-events-none', ...growlyticButtons.value.attrs, type: 'success' }
          // Disable all input in form
          providerMetadata.value.fixedHeader.map(x => (x.disabled = true))
          for (var pKey in providerMetadata.value.dynamicProviders) {
            var pValue = providerMetadata.value.dynamicProviders[pKey]
            if (Array.isArray(pValue.fields)) {
              pValue.fields.map(x => (x.disabled = true))
            }
          }
        }
        formData.value = result
      } catch {
        errorLoad.value = true
      }
      loaded.value = true
      loading.value = false
    }
    onMounted(() => {
      if (!isCreatePage.value) {
        initializeData()
      } else {
        loaded.value = true
      }
    })
    return {
      loaded,
      loading,
      errorLoad,
      isCreatePage,
      formButtons,
      formData,
      providerMetadata,
      busyValidatingEmailGrowlytics,
      showProgressGrowlyticsValidation,
      failedValidatingEmailGrowlytics,
      successValidatingEmailGrowlytics
    }
  }
}
</script>
<style lang="scss">
#createEmailChannelPage {
  .el-form .el-form-item__label {
    padding-top: 8px;
  }
}
</style>
