<template>
  <el-dialog append-to-body custom-class="fcmPushPopup" title="FCM Push Credentials" v-model="dialogVisible" width="800px">
    <div class="py-5 px-10">
      <el-form :model="form" :rules="formRules" ref="fcmPushForm" label-position="top">
        <!-- Key File -->
        <div class="mb-3">
          <!-- Label -->
          <div class="mb-2" style="font-size: 14px; color: #606266;">Key File - JSON</div>

          <!-- If File Uploaded -->
          <template v-if="form.filename">
            <span class="text-gray-700 text-base pr-3">{{ form.filename }}</span>
            <el-button type="primary" @click="$refs.fcmPushKeyFileUploadInput.click()" size="mini" round plain>
              Change Key File
            </el-button>
          </template>
          <!-- If No File Uploaded -->
          <template v-else>
            <el-button type="primary" @click="$refs.fcmPushKeyFileUploadInput.click()" size="mini" round plain>
              Upload Key File
            </el-button>
          </template>

          <!-- Error Message -->
          <div v-if="keyFileError" class="text-red-500 text-sm mt-2">{{ keyFileError }}</div>

          <!-- File Uploader Input -->
          <input
            ref="fcmPushKeyFileUploadInput"
            @change="onUploadedKeyFile"
            class="hidden"
            type="file"
            multiple="false"
            accept=".json"
          />
        </div>
      </el-form>
    </div>

    <template #footer>
      <el-button type="primary" @click="saveChanges">Save Changes</el-button>
      <el-button @click="dialogVisible = false">Cancel</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { saveChannelProviderInfo, createProviderItem } from '@/services/channelService'

export default {
  data() {
    return {
      dialogVisible: false,
      savingChanges: false,

      existingChannelId: null,

      form: null,
      formTemplate: {
        settings: null,
        filename: ''
      },

      // Key File Additional Fields.
      keyFileError: null
    }
  },

  methods: {
    showDialog(existingChannelId, inputData) {
      this.existingChannelId = existingChannelId || null

      if (inputData) {
        this.form = this.overrideJson(JSON.parse(JSON.stringify(this.formTemplate)), JSON.parse(JSON.stringify(inputData)))
      } else {
        this.form = JSON.parse(JSON.stringify(this.formTemplate))
      }

      this.dialogVisible = true
    },

    onUploadedKeyFile(e) {
      const file = e.target.files[0]
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = async e => {
        this.form.settings = JSON.parse(e.target.result)
        this.form.filename = file.name
        this.keyFileError = null
      }
      // Clear selected file.
      e.target.value = null
    },

    async validateForm(form) {
      // Valiate Key File.
      this.keyFileError = null
      if (!this.form.filename) {
        this.keyFileError = 'Key File is required.'
      }

      if (!this.keyFileError) {
        return true
      }

      return false
    },

    async saveChanges() {
      // Validate Form
      const isValid = await this.validateForm(this.$refs.fcmPushForm)
      if (!isValid) return null

      // Save Changes.
      try {
        const apiToCall = this.existingChannelId ? saveChannelProviderInfo : createProviderItem

        const apiParams = {
          id: this.existingChannelId ? this.existingChannelId : undefined,
          settings: this.form,
          channel_type: 'mobilePush',
          provider_name: 'FCM Push Credentials',
          provider_type: 'fcm'
        }

        const response = await apiToCall(apiParams)

        if (response.data.success) {
          this.$message({ message: 'Settings saved successfully.', type: 'success' })
          this.$emit('settingsChanged')
          this.dialogVisible = false
        } else {
          this.$alert('Something went wrong when saving changes. Please try again later!', 'Error', {
            confirmButtonText: 'OK'
          })
        }
      } catch (err) {
        this.$alert('Something went wrong when saving changes. Please try again later!', 'Error', {
          confirmButtonText: 'OK'
        })
      } finally {
        this.busySubmitting = false
      }
    }
  },

  mounted() {
    // Set Form.
    this.form = JSON.parse(JSON.stringify(this.formTemplate))
  }
}
</script>

<style>
.fcmPushPopup {
}
</style>
