<template>
  <div class="hidden md:flex md:flex-shrink-0">
    <div class="flex flex-col w-64">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-col flex-grow border-r border-gray-200 pt-5 pb-4 bg-white overflow-y-auto">
        <div class="flex items-center flex-shrink-0 px-5 mt-2">
          <img class="h-8 w-auto" src="/img/logo_dark_hr.png" alt="Growlytics - Settings" />
        </div>

        <div class="mt-3 mx-2 flex-grow flex flex-col">
          <el-menu router class="overflow-y-auto flex-1" :default-active="defaultActiveMenu">
            <div v-for="item in items" :key="item.key" class="mx-1">
              <el-menu-item-group class="menuGroup">
                <div class="mx-2 text-xs text-gray-500">{{ item.title }}</div>
                <el-menu-item
                  v-for="subItem in item.children"
                  :key="subItem.key"
                  :route="subItem.route"
                  :index="subItem.route.name"
                  >{{ subItem.label }}</el-menu-item
                >
              </el-menu-item-group>
            </div>
          </el-menu>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
export default {
  setup() {
    const route = useRoute()
    const items = [
      {
        title: 'General',
        key: 'general',
        children: [
          {
            key: 'brand-info',
            label: 'Brand Info',
            route: { name: 'HomePage' }
          },
          { key: 'users', label: 'Users', route: { name: 'UserListPage' } },
          { key: 'apikeys', label: 'Api Keys', route: { name: 'ApiCredentialsPage' } },
          { key: 'campaign-settings', label: 'Campaign Settings', route: { name: 'CampaignSettingsPage' } }
        ]
      },
      {
        title: 'Integrations',
        key: 'integrations',
        children: [
          { key: 'email', label: 'Email', route: { name: 'EmailPage' } },
          { key: 'social-media', label: 'Social Media', route: { name: 'SocialMediaView' } },
          { key: 'sms', label: 'SMS', route: { name: 'SMSListPage' } },
          { key: 'mobilepush', label: 'Mobile Push', route: { name: 'MobilePushPage' } },
          { key: 'whatsapp', label: 'WhatsApp', route: { name: 'WhatsAppListPage' } },
          { key: 'webpush', label: 'Web Push', route: { name: 'WebpushPage' } },
          { key: 'integration', label: 'Connected Apps', route: { name: 'IntegrationListView' } }
        ]
      }
    ]
    const defaultActiveMenu = computed(() => route.name)
    return {
      items,
      defaultActiveMenu
    }
  }
}
</script>
<style lang="scss">
$menuHeight: 35px;

.sideBarBackBtn {
  background: #284bdb;
  border-color: #284bdb;
}

.menuGroup {
  margin-bottom: 20px;
  .el-menu-item,
  .el-submenu__title {
    height: $menuHeight;
    line-height: $menuHeight;
    letter-spacing: 1px;
    font-size: 14px !important;
    overflow: hidden;
    color: rgb(75, 85, 99);
  }

  .el-menu-item {
    border-radius: 5px;
    margin-top: 2px;

    padding-left: 10px !important;
  }

  .el-menu-item.is-active {
    outline: none;
    background-color: #e8eeef;
    .fa {
      color: white;
    }

    .subMenu {
      color: white;
    }
  }
}
</style>
