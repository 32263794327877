export default {
  methods: {
    overrideJson(original, modified) {
      var isObjectAndNotArray = function(object) {
        return typeof object === 'object' && !Array.isArray(object)
      }

      // 'createNew' defaults to false
      var overwriteKeys = function(baseObject, overrideObject, createNew) {
        if (!baseObject) {
          baseObject = {}
        }
        if (createNew) {
          baseObject = JSON.parse(JSON.stringify(baseObject))
        }
        if (!overrideObject) return overrideObject
        Object.keys(overrideObject).forEach(function(key) {
          if (isObjectAndNotArray(baseObject[key]) && isObjectAndNotArray(overrideObject[key])) {
            overwriteKeys(baseObject[key], overrideObject[key])
          } else {
            baseObject[key] = overrideObject[key]
          }
        })
        return baseObject
      }

      return overwriteKeys(original, modified, false)
    },

    // Show error toast message
    errorToast(errorMsg, duration) {
      this.$message.error({
        message: errorMsg,
        showClose: true,
        duration: duration || 3000
      })
    },

    successToast(message, duration) {
      this.$message({
        type: 'success',
        message: message,
        showClose: true,
        duration: duration || 3000
      })
    },

    warningToast(message, duration) {
      this.$message({
        type: 'warning',
        message: message,
        showClose: true,
        duration: duration || 3000
      })
    },

    reportError() {
      //   if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
      //     Sentry.captureException(error)
      //   } else {
      //     console.error(error)
      //   }
    }

    /** ****************************End: Util Methods *************************************/
  },
  data: function() {
    return {
      providerTypeDisplayNames: {
        // Email
        sendgrid: 'Sendgrid',
        'elastic-email': 'Elastic Email',
        amazon_ses: 'Amazon SES',
        'growlytics-email': 'Growlytics Email',
        smtp: 'Custom SMTP',
        'pepipost-email': 'Pepipost - NetCore',
        'mailgun-email': 'Mailgun',

        // SMS
        msg91: 'MSG 91',
        'your-bulk-sms': 'Your Bulk SMS',
        'gupshup-sms': 'Gupshup SMS',
        'kaleyra-sms': 'Kaleyra SMS',
        'vasudev-sms': 'Vasudev SMS',
        'sms-alert-sms': 'SMS Alert',
        'value-first-sms': 'Value First (Deprecated)',
        'value-first-v2-sms': 'Value First',
        'nimbus-sms': 'Nimbus SMS',
        'kenscio-sms': 'Kenscio SMS',
        'trust-signal-sms': 'TrustSignal SMS',

        // WhatsApp
        whatsapp_gupshup: 'Gupshup WhatsApp Old - Depricated',
        whatsapp_gupshup_v1: 'Gupshup WhatsApp',
        whatsapp_gupshup_enterprise: 'Gupshup Enterprise WhatsApp',
        whatsapp_infobip: 'Infobip WhatsApp',
        whatsapp_freshchat: 'FreshChat WhatsApp',
        whatsapp_zoko: 'Zoko',
        whatsapp_interakt: 'Interakt',
        whatsapp_businessonbot: 'Business On Bot',
        whatsapp_dialog360: 'Dialog 360',
        whatsapp_wati: 'Wati',
        whatsapp_valuefirst: 'ValueFirst',

        // Social Media
        facebook: 'Facebook',
        google: 'Google',

        // Other
        fcm: 'Firebase FCM',
        vapid: 'Vapid',
        apns: 'Apple Notif Service'
      },

      RGBAToHexA(r, g, b, a) {
        r = r.toString(16)
        g = g.toString(16)
        b = b.toString(16)
        a = Math.round(a * 255).toString(16)

        if (r.length === 1) r = '0' + r
        if (g.length === 1) g = '0' + g
        if (b.length === 1) b = '0' + b
        if (a.length === 1) a = '0' + a

        const hex = '#' + r + g + b + a
        return hex
      }
    }
  }
}
