import {
  fetchWebPushVapidProviderDetails,
  generateWebPushVapidCred,
  saveChannelProviderInfo,
  createProviderItem
} from '@/services/channelService'
// import { generateID } from '@/utils/generate'
import { stringIsImageURL } from '@/utils/regex'
import WPNotificationBox from './components/WPNotificationBox.vue'
import { defaultWebpushIcon } from './utils'

import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import { CheckCircleIcon } from '@heroicons/vue/solid'

export default {
  components: {
    WPNotificationBox,
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
    CheckCircleIcon
  },
  data() {
    var validateIconURL = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the icon url'))
      } else {
        if (stringIsImageURL(this.webPush.settings.icon)) {
          this.$refs.webPushForm.validateField('settings.icon')
        } else {
          callback(new Error('Please input the valid icon url'))
        }
        callback()
      }
    }
    return {
      // WebPush Form
      webPush: {
        provider_name: 'webPush',
        provider_type: 'vapid',
        channel_type: 'webPush',
        settings: {
          publicVapidKey: '',
          privateVapidKey: '',
          title: 'Would you like to receive notifications?',
          message: 'Subscribe to notifications for latest offers and announcements.',
          icon: defaultWebpushIcon,
          color: '#516c9c',
          acceptText: 'ALLOW',
          denyText: 'NO THANKS',
          displayPosition: 'center',
          displayPositionv1: {
            desktop: 'bottom-center',
            mobile: 'bottom-center'
          },
          enabled: true,
          autoAsk: true,

          optinType: 'two-step',
          showAfterSeconds: 10,
          askAgainAfter: 24
        }
      },

      optinTypes: [
        {
          id: 'one-step',
          title: '1 Click Opt-in',
          description: 'Push permission is asked directly from browser. You can configure the time to show the opt-in.'
        },
        {
          id: 'two-step',
          title: '2 Step Opt-In',
          description:
            'Firstly, users are shown a banner on desktop. If the user accepts it, only then push permission is asked from browser.'
        },
        {
          id: 'self-hosted',
          title: 'Self Handled Opt-In',
          description:
            'An advanced setting where you can selectively show the Browser Opt-in on specific pages or after certain actions.'
        }
      ],

      displayPositionList: {
        desktop: {
          'top-left': 'Top-Left',
          'top-center': 'Top-Center',
          'top-right': 'Top-Right',
          'bottom-left': 'Bottom-Left',
          'bottom-center': 'Bottom-Center',
          'bottom-right': 'Bottom-Right'
        },
        mobile: {
          'top-center': 'Top',
          'bottom-center': 'Bottom'
        }
      },

      // WebPush Form Validation
      webPushFormRules: {
        'settings.publicVapidKey': [{ required: true, message: 'Please input Public Key' }],
        'settings.privateVapidKey': [{ required: true, message: 'Please input Private Key' }],
        'settings.title': [
          { required: true, message: 'Please provide title text.' },
          { min: 1, max: 50, message: 'Length should be 1 to 50', trigger: 'blur' }
        ],
        'settings.message': [
          { required: true, message: 'Please enter message text.' },
          { min: 1, max: 100, message: 'Length should be 1 to 100', trigger: 'blur' }
        ],
        'settings.icon': [
          { max: 10000, message: 'Icon can not exceed 10,000 characters' },
          { required: true, message: 'Please provide icon url.' },
          { validator: validateIconURL, trigger: 'blur' }
        ],
        'settings.acceptText': [
          { required: true, message: 'Please provide accept button text.' },
          { min: 1, max: 20, message: 'Length should be 1 to 20', trigger: 'blur' }
        ],
        'settings.denyText': [
          { required: true, message: 'Please provide deny button text.' },
          { min: 1, max: 20, message: 'Length should be 1 to 20', trigger: 'blur' }
        ]
      },
      loadingData: false,
      loadedOk: false,
      loadedError: false,
      // loader
      busySubmitting: false,
      busyGeneratingCredential: false
    }
  },
  computed: {
    isCreateNew() {
      return !this.webPush.id
    }
  },
  methods: {
    submitWebPushDetail(formName) {
      const vm = this
      this.$refs[formName].validate(validVal => {
        if (!validVal) {
          vm.$message({ message: 'Your request changes is not validated. Please check!', type: 'warning' })
          return false
        } else {
          this.busySubmitting = true
          const apiToCall = !this.isCreateNew ? saveChannelProviderInfo : createProviderItem
          setTimeout(() => {
            apiToCall(vm.webPush)
              .then(() => {
                this.fetchWebPushProviderDetails()
                this.$message({ message: 'Settings saved successfully.', type: 'success' })
              })
              .catch(() => {
                this.$alert('Something went wrong when saving changes. Please try again later!', 'Error', {
                  confirmButtonText: 'OK'
                })
              })
              .finally(() => {
                this.busySubmitting = false
              })
          }, 500)
        }
      })
    },

    async generateWebPushVapidCred() {
      this.busyGeneratingCredential = true

      try {
        const result = await generateWebPushVapidCred()

        this.webPush.settings.publicVapidKey = result.data.publicKey
        this.webPush.settings.privateVapidKey = result.data.privateKey

        this.$message({ message: 'New Public Key and Private Key has been generated', type: 'success' })
      } catch (err) {
        this.$message({ message: 'Failed to generate vapid credentials. Please contact support.', type: 'error' })
      }
      this.busyGeneratingCredential = false
    },

    fetchWebPushProviderDetails() {
      this.loadingData = true
      fetchWebPushVapidProviderDetails({ providerType: 'webPush' })
        .then(({ data }) => {
          this.loadedOk = true
          this.loadedError = false
          if (Array.isArray(data) && data.length) {
            const result = data[0]
            delete result.company_id

            this.webPush = this.overrideJson(this.webPush, result)
          }
        })
        .catch(e => {
          console.error(e)
          this.loadedError = true
        })
        .finally(() => {
          this.loadingData = false
        })
    },

    onThemeColorChange(color) {
      this.webPush.settings.color = color
    },

    openColorPicker() {
      if (this.$refs.colorPickerEle) {
        if (!this.$refs.colorPickerEle.showPicker) {
          setTimeout(() => {
            this.$refs.colorPickerEle.showPicker = true
          }, 300)
        } else {
          setTimeout(() => {
            this.$refs.colorPickerEle.showPicker = false
          }, 300)
        }
      }
    }
  },
  mounted() {
    this.fetchWebPushProviderDetails()
  }
}
