import router from '@/router'
import SMSDetailPage from './views/SMSDetailPage.vue'
import SMSListPage from './views/SMSListPage.vue'

router.addRoute({
  path: '/channels/sms',
  name: 'SMSListPage',
  component: SMSListPage,
  meta: {
    layout: 'dashboard'
  }
})

router.addRoute({
  path: '/channels/sms/:id',
  name: 'SMSDetailPage',
  component: SMSDetailPage,
  meta: {
    layout: 'dashboard'
  }
})
